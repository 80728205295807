<template>
  <div id="p34sp8">
    <HeaderComponent />
    <div class="a1">
      <div v-if="loading">...</div>
      <div v-else-if="error">...</div>
      <div v-else>
        <div v-for="(typeData, typeName) in goldData" :key="typeName">
          <div v-if="typeName === 'type1'">
            <h3>코스피</h3>
          </div>
          <div v-else-if="typeName === 'type2'">
            <h3>나스닥</h3>
          </div>
          <table>
            <thead>
              <tr>
                <th>N</th>
                <th>연속</th>
                <th>누적</th>
                <th>종목명</th>
                <th>현재가</th>
                <th>전일비</th>
                <th>등락률</th>
                <th>거래량</th>
                <th>시가</th>
                <th>고가</th>
                <th>저가</th>
                <th>PER</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in typeData" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.consecutive }}</td>
                <td>{{ item.cumulative }}</td>
                <td>{{ item.stockName }}</td>
                <td>{{ item.currentPrice }}</td>
                <td>{{ item.changeAmount }}</td>
                <!-- <td>{{ item.changeRate }}%</td> -->
                <td :style="{ color: item.changeRate < 0 ? 'blue' : 'red' }">
                  {{ item.changeRate }}%
                </td>
                <td>{{ item.volume }}</td>
                <td>{{ item.openPrice }}</td>
                <td>{{ item.highPrice }}</td>
                <td>{{ item.lowPrice }}</td>
                <td>{{ item.peRatio }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import useUserStore from '@/stores/user.js'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import axios from 'axios'
export default {
  data() {
    return {
      goldData: null,
      loading: true,
      error: null
    }
  },
  components: {
    FooterComponent,
    HeaderComponent
  },
  mounted() {
    const userStore = useUserStore()
    userStore.activeIndex = '3-4'
    this.fetchGoldData()
  },
  methods: {
    fetchGoldData() {
      axios
        .get('https://sj-investing.com/ideal/api/siseUpper')
        .then((response) => {
          if (response.data && response.data.code === 200) {
            this.goldData = response.data.data
          } else {
            this.error = 'Invalid response from the API'
          }
        })
        .catch((error) => {
          console.error('Error fetching gold data:', error)
          this.error = error.message || 'Error fetching gold data'
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
#p34sp8 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .a1 {
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    overflow: auto;

    table {
      width: 100%;
      border-collapse: collapse;
      background-color: #ffffff;
    }

    th,
    td {
      border: 1px solid #e7e7e7;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #ffffff;
    }
  }
}
</style>
