import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import P31SP5View from '@/views/P31SP5View.vue'
import P32SP6View from '@/views/P32SP6View.vue'
import P33SP7View from '@/views/P33SP7View.vue'
import P34SP8View from '@/views/P34SP8View.vue'
import P35SP9View from '@/views/P35SP9View.vue'
import P41SP10View from '@/views/P41SP10View.vue'
import P42SP11View from '@/views/P42SP11View.vue'
import P43SP12View from '@/views/P43SP12View.vue'
import P44SP13View from '@/views/P44SP13View.vue'
import P45SP14View from '@/views/P45SP14View.vue'
import P46SP15View from '@/views/P46SP15View.vue'
import P51View from '@/views/P51View.vue'
import P61View from '@/views/P61View.vue'
import P21View from '@/views/P21View.vue'
import P22View from '@/views/P22View.vue'
import P23View from '@/views/P23View.vue'
import P24View from '@/views/P24View.vue'
import P25View from '@/views/P25View.vue'
import P26View from '@/views/P26View.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/p21',
      name: 'p21',
      component: P21View
    },
    {
      path: '/p22',
      name: 'p22',
      component: P22View
    },
    {
      path: '/p23',
      name: 'p23',
      component: P23View
    },
    {
      path: '/p24',
      name: 'p24',
      component: P24View
    },
    {
      path: '/p25',
      name: 'p25',
      component: P25View
    },
    {
      path: '/p26',
      name: 'p26',
      component: P26View
    },
    {
      path: '/p31sp5',
      name: 'p31sp5',
      component: P31SP5View
    },
    {
      path: '/p32sp6',
      name: 'p32sp6',
      component: P32SP6View
    },
    {
      path: '/p33sp7',
      name: 'p33sp7',
      component: P33SP7View
    },
    {
      path: '/p34sp8',
      name: 'p34sp8',
      component: P34SP8View
    },
    {
      path: '/p35sp9',
      name: 'p35sp9',
      component: P35SP9View
    },
    {
      path: '/p41sp10',
      name: 'p41sp10',
      component: P41SP10View
    },
    {
      path: '/p42sp11',
      name: 'p42sp11',
      component: P42SP11View
    },
    {
      path: '/p43sp12',
      name: 'p43sp12',
      component: P43SP12View
    },
    {
      path: '/p44sp13',
      name: 'p44sp13',
      component: P44SP13View
    },
    {
      path: '/p45sp14',
      name: 'p45sp14',
      component: P45SP14View
    },
    {
      path: '/p46sp15',
      name: 'p46sp15',
      component: P46SP15View
    },
    {
      path: '/p51',
      name: 'p51',
      component: P51View
    },
    {
      path: '/p61',
      name: 'p61',
      component: P61View
    }
  ]
})

export default router
