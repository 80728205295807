<template>
  <div id="footer">
    <div class="a2">
      © Copyright 2024 Morgan Creek Capital Management, LLC. All rights reserved.
    </div>
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted } from 'vue'
// ------------------------------ //
// 数据
// ------------------------------ //
// ------------------------------ //
// 方法
// ------------------------------ //
// ...
// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => {})
// ------------------------------ //
</script>

<style lang="scss" scoped>
// PC
@media screen and (min-width: 1024px) {
  #footer {
    width: 100%;
    height: 60px;

    .a2 {
      width: 100%;
      height: 60px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #253b23;
    }
  }
}
// Mobile
@media screen and (max-width: 1024px) {
  #footer {
    width: 100%;
    height: 60px;

    .a2 {
      width: 100%;
      height: 60px;
      padding: 0 15px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #253b23;
    }
  }
}
</style>
