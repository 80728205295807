<template>
  <div id="p46sp15">
    <HeaderComponent />
    <div class="a1">
      <h1>국내경제지표</h1>
      <table>
        <thead>
          <tr>
            <th>상품명</th>
            <th>
              종가<span class="sort-button" @click="sortTable('closePrice')">
                {{
                  sortStatus.closePrice === 'none'
                    ? '&#8597;'
                    : sortStatus.closePrice === 'asc'
                      ? '&#8595;'
                      : '&#8593;'
                }}
              </span>
            </th>
            <th>
              전일비<span class="sort-button" @click="sortTable('changeAmount')">
                {{
                  sortStatus.changeAmount === 'none'
                    ? '&#8597;'
                    : sortStatus.changeAmount === 'asc'
                      ? '&#8595;'
                      : '&#8593;'
                }}
              </span>
            </th>
            <th>
              전일비(%)<span class="sort-button" @click="sortTable('changeRate')">
                {{
                  sortStatus.changeRate === 'none'
                    ? '&#8597;'
                    : sortStatus.changeRate === 'asc'
                      ? '&#8595;'
                      : '&#8593;'
                }}
              </span>
            </th>
            <th>
              업데이트주기<span class="sort-button" @click="sortTable('openPrice')">
                {{
                  sortStatus.openPrice === 'none'
                    ? '&#8597;'
                    : sortStatus.openPrice === 'asc'
                      ? '&#8595;'
                      : '&#8593;'
                }}
              </span>
            </th>
            <th>
              업데이트일<span class="sort-button" @click="sortTable('highPrice')">
                {{
                  sortStatus.highPrice === 'none'
                    ? '&#8597;'
                    : sortStatus.highPrice === 'asc'
                      ? '&#8595;'
                      : '&#8593;'
                }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in exchangeData" :key="item.id">
            <td :style="{ borderLeft: item.changeRate < 0 ? '4px solid blue' : '4px solid red' }">
              {{ item.productName }}
            </td>
            <td>{{ item.closePrice }}</td>
            <td :style="{ color: item.electricityFee < 0 ? 'blue' : 'red' }">
              {{ item.electricityFee }}
            </td>
            <td :style="{ color: item.electricityRatio < 0 ? 'blue' : 'red' }">
              {{ item.electricityRatio }}%
            </td>
            <td>{{ item.updateCycle }}</td>
            <td>{{ item.updateDate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import axios from 'axios'
import useUserStore from '@/stores/user.js'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'ExchangeData',
  components: { FooterComponent, HeaderComponent },
  data() {
    return {
      exchangeData: [],
      latestUpdateTime: '',
      currentSortField: '',
      currentSortAsc: true,
      sortStatus: {
        closePrice: 'none',
        changeAmount: 'none',
        changeRate: 'none',
        openPrice: 'none',
        highPrice: 'none',
        lowPrice: 'none',
        tradeDate: 'none'
      }
    }
  },
  mounted() {
    const userStore = useUserStore()
    userStore.activeIndex = '4-6'
    this.fetchExchangeData()
  },
  methods: {
    async fetchExchangeData() {
      console.log('Order:', this.currentSortField, 'Ascending:', this.currentSortAsc)
      try {
        const response = await axios.post('https://sj-investing.com/ideal/api/indicators', {
          order: this.currentSortField,
          asc: this.currentSortAsc
        })

        if (response.data && response.data.code === 200) {
          this.exchangeData = response.data.data
          this.latestUpdateTime =
            response.data.data.length > 0 ? response.data.data[0].createTime : ''
        } else {
          console.error('Invalid response from the API:', response.data)
        }
      } catch (error) {
        console.error('Error fetching exchange data:', error)
      }
    },
    sortTable(field) {
      if (this.currentSortField === field) {
        this.currentSortAsc = !this.currentSortAsc
        this.sortStatus[field] = this.currentSortAsc ? 'asc' : 'desc'
      } else {
        this.currentSortField = field
        this.currentSortAsc = true
        this.sortStatus[field] = 'asc'
      }
      for (let key in this.sortStatus) {
        if (key !== field) {
          this.sortStatus[key] = 'none'
        }
      }
      this.fetchExchangeData()
    }
  }
}
</script>

<style scoped>
#p46sp15 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .a1 {
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    overflow: auto;

    .custom-line {
      border: none;
      height: 2px; /* 横线高度 */
      background-color: #333; /* 横线颜色 */
      /* margin: 20px auto; */
      width: 95%;
    }
    .titA {
      text-align: center;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th,
    td {
      border: 1px solid #ddd;
      /* padding: 8px; */
      text-align: left;
      text-align: center;
    }

    thead {
      background-color: #f4f4f4;
    }
    .sort-button {
      cursor: pointer;
      margin-left: 5px; /* 或适当的间距 */
      /* opacity: 0; */
      transition: opacity 0.3s;
    }

    /* th:hover .sort-button {
      opacity: 1;

    } */
  }
}
</style>
