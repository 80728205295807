<template>
    <div>
      <!-- 内容区域 -->
      <div class="content">
        <div class="combined-tradingview">
          <!-- AsianK1 组件 -->
          <AsianK1/>
          <!-- AsianK2 组件 -->
          <AsianK2/>
        </div>
      </div>
    </div>
  </template>
  
      
      <script>
     
  
      import  AsianK1 from './AsianK1.vue';
      import AsianK2 from './AsianK2.vue';
     
      export default {
        data() {
        return {
         
        };
      },
        components: {
       
            AsianK1,
            AsianK2
      },
      
        
      };
      </script>
      
      <style scoped>
    
    @media (max-width: 800px) {
      
  
    
    .combined-tradingview {
      flex-direction: column !important; 
     
    }
    
 
    
    }
    
    .combined-tradingview {
    display: flex; /* 使用 Flexbox 布局 */
    flex-direction: row; /* 子元素横向排列 */
    height: 100%;
    gap: 20px;
    margin: 0 20px;
  }

  .combined-tradingview > * {
    flex: 1; /* 子组件平分空间 */
   
  }
  
    
      </style>
      
      