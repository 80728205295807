<template>
  <div>
    <!-- <table>
        <thead>
            <tr>
                <th>
        순매수</th>
    </tr>
    </thead>
    </table> -->

    <table>
      <thead>
        <tr>
          <th>상품명</th>
          <th>심볼</th>
          <th>
            종가<span class="sort-button" @click="sortTable('closePrice')">
              {{
                sortStatus.closePrice === 'none'
                  ? '&#8597;'
                  : sortStatus.closePrice === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            전일비<span class="sort-button" @click="sortTable('changeAmount')">
              {{
                sortStatus.changeAmount === 'none'
                  ? '&#8597;'
                  : sortStatus.changeAmount === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            전일비(%)<span class="sort-button" @click="sortTable('changeRate')">
              {{
                sortStatus.changeRate === 'none'
                  ? '&#8597;'
                  : sortStatus.changeRate === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            시가<span class="sort-button" @click="sortTable('openPrice')">
              {{
                sortStatus.openPrice === 'none'
                  ? '&#8597;'
                  : sortStatus.openPrice === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            고가<span class="sort-button" @click="sortTable('highPrice')">
              {{
                sortStatus.highPrice === 'none'
                  ? '&#8597;'
                  : sortStatus.highPrice === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            저가<span class="sort-button" @click="sortTable('lowPrice')">
              {{
                sortStatus.lowPrice === 'none'
                  ? '&#8597;'
                  : sortStatus.lowPrice === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            거래일<span class="sort-button" @click="sortTable('tradeDate')">
              {{
                sortStatus.tradeDate === 'none'
                  ? '&#8597;'
                  : sortStatus.tradeDate === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in exchangeData" :key="item.id">
          <td :style="{ borderLeft: item.changeRate < 0 ? '4px solid blue' : '4px solid red' }">
            {{ item.productName }}
          </td>
          <td>{{ item.symbol }}</td>
          <td>{{ item.closePrice }}</td>
          <td :style="{ color: item.changeAmount < 0 ? 'blue' : 'red' }">
            {{ item.changeAmount }}
          </td>
          <td :style="{ color: item.changeRate < 0 ? 'blue' : 'red' }">{{ item.changeRate }}%</td>
          <td>{{ item.openPrice }}</td>
          <td>{{ item.highPrice }}</td>
          <td>{{ item.lowPrice }}</td>
          <td>{{ item.tradeDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExchangeData',
  data() {
    return {
      exchangeData: [],
      latestUpdateTime: '',
      currentSortField: '',
      currentSortAsc: true,
      sortStatus: {
        closePrice: 'none',
        changeAmount: 'none',
        changeRate: 'none',
        openPrice: 'none',
        highPrice: 'none',
        lowPrice: 'none',
        tradeDate: 'none'
      }
    }
  },
  mounted() {
    this.fetchExchangeData()
  },
  methods: {
    async fetchExchangeData() {
      console.log('Order:', this.currentSortField, 'Ascending:', this.currentSortAsc)
      try {
        const response = await axios.post('https://sj-investing.com/ideal/api/currenciesDay', {
          order: this.currentSortField,
          asc: this.currentSortAsc
        })

        if (response.data && response.data.code === 200) {
          this.exchangeData = response.data.data
          this.latestUpdateTime =
            response.data.data.length > 0 ? response.data.data[0].createTime : ''
        } else {
          console.error('Invalid response from the API:', response.data)
        }
      } catch (error) {
        console.error('Error fetching exchange data:', error)
      }
    },
    sortTable(field) {
      if (this.currentSortField === field) {
        this.currentSortAsc = !this.currentSortAsc
        this.sortStatus[field] = this.currentSortAsc ? 'asc' : 'desc'
      } else {
        this.currentSortField = field
        this.currentSortAsc = true
        this.sortStatus[field] = 'asc'
      }
      for (let key in this.sortStatus) {
        if (key !== field) {
          this.sortStatus[key] = 'none'
        }
      }
      this.fetchExchangeData()
    }
  }
}
</script>

<style scoped>
.custom-line {
  border: none;
  height: 2px; /* 横线高度 */
  background-color: #333; /* 横线颜色 */
  /* margin: 20px auto; */
  width: 95%;
}
.titA {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  /* padding: 8px; */
  text-align: left;
  text-align: center;
}

thead {
  background-color: #f4f4f4;
}
.sort-button {
  cursor: pointer;
  margin-left: 5px; /* 或适当的间距 */
  /* opacity: 0; */
  transition: opacity 0.3s;
}

/* th:hover .sort-button {
    opacity: 1; 
    
  } */
</style>
