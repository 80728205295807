<template>
  <div id="header">
    <div class="a1" @click="onLogo">
      <img src="@/assets/image/logo.png" alt="" />

    </div>
<!--    <button onclick="window.location.href = 'https://mobile.mgitop.top';" class="btn btn-primary" style="font-size: 2em; background-color: #233c98; border-color: #233c98; color: white;">-->
<!--      외자기관 VIP 전용-->
<!--    </button>-->


    <div class="a2">
      <el-menu :default-active="userStore.activeIndex" mode="horizontal" @select="handleSelect" :ellipsis="false"
        style="border-bottom: none !important">
        <el-menu-item index="1">홈</el-menu-item>
        <el-sub-menu index="2">
          <template #title>회사 안내</template>
          <el-menu-item index="2-1">우리 팀</el-menu-item>
          <el-menu-item index="2-2">컨설팅 & 리크루트</el-menu-item>
          <el-menu-item index="2-3">다양화 프로세스</el-menu-item>
          <el-menu-item index="2-4">기회주의 지분</el-menu-item>
          <el-menu-item index="2-5">M&A</el-menu-item>
          <el-menu-item index="2-6">우리에 대해</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>종목체크</template>
          <el-menu-item index="3-1">종목종합소식</el-menu-item>
          <el-menu-item index="3-2">상승종목</el-menu-item>
          <el-menu-item index="3-3">급등종목</el-menu-item>
          <el-menu-item index="3-4">상한가 종목</el-menu-item>
          <el-menu-item index="3-5">거래량상위종목</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4">
          <template #title>시장지표</template>
          <el-menu-item index="4-1">전종목시세</el-menu-item>
          <el-menu-item index="4-2">투자주체별 동향</el-menu-item>
          <el-menu-item index="4-3">외국인매매동향</el-menu-item>
          <el-menu-item index="4-4">외환</el-menu-item>
          <el-menu-item index="4-5">채권/금리</el-menu-item>
          <el-menu-item index="4-6">국내경제지표</el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
  </div>
  <div id="m-header">
    <div class="a1">
      <div class="b1" @click="onLogo">
        <img class="logo" src="@/assets/image/logo.png" alt="" />
        <div>Mckinsey Global Institute</div>
      </div>
      <div class="b2" @click="openMenu = !openMenu">메뉴</div>
    </div>
    <div class="a2" v-if="openMenu">
      <div class="b1" @click="handleSelect('1')">홈</div>
      <div class="divider" />
      <div class="b1" style="background-color: #f2f6fc">회사 안내</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('2-1')">- 우리 팀</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('2-2')">- 컨설팅 & 리크루트</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('2-3')">- 다양화 프로세스</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('2-4')">- 기회주의 지분</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('2-5')">- M&A</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('2-6')">- 우리에 대해</div>
      <div class="divider" />
      <div class="b1" style="background-color: #f2f6fc">종목체크</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('3-1')">- 종목종합소식</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('3-2')">- 상승종목</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('3-3')">- 급등종목</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('3-4')">- 상한가 종목</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('3-5')">- 거래량상위종목</div>
      <div class="divider" />
      <div class="b1" style="background-color: #f2f6fc">시장지표</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('4-1')">- 전종목시세</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('4-2')">- 투자주체별 동향</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('4-3')">- 외국인매매동향</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('4-4')">- 외환</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('4-5')">- 채권/금리</div>
      <div class="divider" />
      <div class="b1" @click="handleSelect('4-6')">- 국내경제지표</div>
      <div class="divider" />
    </div>
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted, ref } from 'vue'
import router from '@/router/index.js'
import useUserStore from '@/stores/user.js'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
const openMenu = ref(false)
// ------------------------------ //
// 方法
// ------------------------------ //
function onLogo() {
  router.push({ name: 'home' })
}
function handleSelect(key) {
  switch (key) {
    case '1':
      router.push({ name: 'home' })
      break
    case '2-1':
      router.push({ name: 'p21' })
      break
    case '2-2':
      router.push({ name: 'p22' })
      break
    case '2-3':
      router.push({ name: 'p23' })
      break
    case '2-4':
      router.push({ name: 'p24' })
      break
    case '2-5':
      router.push({ name: 'p25' })
      break
    case '2-6':
      router.push({ name: 'p26' })
      break
    case '3-1':
      router.push({ name: 'p31sp5' })
      break
    case '3-2':
      router.push({ name: 'p32sp6' })
      break
    case '3-3':
      router.push({ name: 'p33sp7' })
      break
    case '3-4':
      router.push({ name: 'p34sp8' })
      break
    case '3-5':
      router.push({ name: 'p35sp9' })
      break
    case '4-1':
      router.push({ name: 'p41sp10' })
      break
    case '4-2':
      router.push({ name: 'p42sp11' })
      break
    case '4-3':
      router.push({ name: 'p43sp12' })
      break
    case '4-4':
      router.push({ name: 'p44sp13' })
      break
    case '4-5':
      router.push({ name: 'p45sp14' })
      break
    case '4-6':
      router.push({ name: 'p46sp15' })
      break
    case '5-1':
      router.push({ name: 'p51' })
      break
    case '6-1':
      router.push({ name: 'p61' })
      break
  }
  openMenu.value = false
}
// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => { })
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //
</script>

<style lang="scss" scoped>
img {
  aspect-ratio: 5 / 2;
}

// PC
@media screen and (min-width: 1024px) {
  #m-header {
    display: none;
  }

  .divider {
    border: 0;
    height: 1px;
    background-color: #d3d3d3;
    width: 100%;
  }

  #header {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0 15px;
    border-top: 20px solid #445b41;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);

    .a1 {
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      font-weight: bold;

      img {
        height: 84px;
        cursor: pointer;
      }
    }

    .a2 {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 15px;
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #header {
    display: none;
  }

  #m-header {
    width: 100%;
    height: 101px;
    position: relative;
    left: 0;
    top: 0;
    border-top: 10px solid #445b41;
    background-color: white;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    .a1 {
      width: 100%;
      height: 91px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .b1 {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        .logo {
          height: 64px;
          cursor: pointer;
        }
      }

      .b1x {
        width: 150px;
        height: 32px;
        position: absolute;
        left: 160px;
        top: 29.5px;
        background-color: #3763cc;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .b2 {
        width: 80px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
      }
    }

    .a2 {
      width: 100%;
      position: absolute;
      top: 91px;
      left: 0;
      display: flex;
      flex-direction: column;
      background-color: white;
      z-index: 1000;

      .bx {
        width: 100%;
        height: 45px;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

      .b1 {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
