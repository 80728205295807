<template>
  <div id="p51">
    <HeaderComponent />
    <div class="content">
      <div class="a1">
        <iframe id="responsive-iframe" src="https://www.38.co.kr/html/fund/?o=k" />
      </div>
      <div class="a2">©2024 MBK All rights reserved.</div>
    </div>
  </div>
</template>

<script>
import useUserStore from '@/stores/user.js'
import HeaderComponent from '@/components/HeaderComponent.vue'

export default {
  name: 'ExchangeData',
  components: { HeaderComponent },
  data() {
    return {}
  },
  mounted() {
    const userStore = useUserStore()
    userStore.activeIndex = '5-1'
  }
}
</script>

<style scoped>
#p51 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    height: 100%;
    position: relative;

    .a1 {
      width: 100%;
      height: calc(100% - 50px);
      padding: 10px;
      overflow: auto;

      #responsive-iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: -350px;
        left: 0;
        clip-path: inset(410px 480px 150px 350px);
        transform: scale(1.8);
      }
    }

    .a2 {
      width: 100%;
      height: 50px;
      color: white;
      background-color: #0e3442;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
