import axios from 'axios'

// BaseUrl
// axios.defaults.baseURL = SERVER_BASEURL

// 创建一个axios实例
const instance = axios.create()
instance.defaults.timeout = 60000

/*

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    config.method === "post" && (config.data = qs.stringify(config.data));
    config.headers.Authorization = localStorage.getItem("user/token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Token 缺失或失效 ...
    if (error.response.status === 498) {
      // 释放用户数据, 需要重新登陆 ...
      localStorage.removeItem("user/token"); // 释放 ...
      localStorage.removeItem("user/user"); // 释放 ...
      Refresh(); // 刷新 ...
      return Promise.reject(new Error("Authorization Failure")); // 授权失败
    } else {
      // 输出错误 ...
      console.log(error.response);
      return Promise.reject(error);
    }
  },
);

*/

export default instance
