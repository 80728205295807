<template>
  <div id="p21">
    <HeaderComponent />
    <div class="leadership page">
      <router-link to="/" class="navback box" style="width: 100%;"><el-icon>
          <ArrowLeft />
        </el-icon><span>홈으로 돌아가기</span></router-link>
      <h1 class="title" w>리더십</h1>
      <div class="box team" style="margin-top: 0">
        <div class="pictures grid grid-9" style="gap: 0;">
          <div class="picture" v-for="item in avatarList">
            <el-image :src="item"></el-image>
          </div>
        </div>
        <div class="text center" style="margin-top: 1rem;">
          맥킨지는 경영 파트너, 주주협의회로 알려진 선출직 이사회, 액셀러레이션 팀으로 알려진 글로벌 리더십 팀, 각 지사 및 실무 리더들이 전 세계를 이끌고 있습니다.
        </div>
      </div>
      <div class="box members">
        <div class="grid grid-2">
          <div class="item shadow" v-for="item in members">
            <router-link class="title" :to="item.link">
              <h5>{{ item.title }}</h5><el-icon color="#004fff">
                <ArrowRightBold />
              </el-icon>
            </router-link>
            <p class="text">{{ item.text }}</p>
            <router-link class="see" :to="item.link">
              <h6>구성원 보기<el-icon color="#004fff">
                  <Right />
                </el-icon></h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="box aspiration">
        <h5 class="subtitle center">우리의 염원</h5>
        <div class="flex">
          <div class="item flex shadow" style="gap: 1rem;">
            <el-image :src="img_1" class="img flex-1" fit="fill"></el-image>
            <div style="flex: 1;" class="flex-1">
              <router-link class="title"
                to="https://www.mckinsey.com/about-us/overview/sustainable-and-inclusive-growth">
                <h2>지속 가능하고 포용적인 성장 가속화<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text" style="margin-top: 1rem;">우리는 항상 고객의 성장 잠재력을 믿어왔습니다. 오늘날 우리는 지속가능성, 포용성 및 경제 성장을 동시에 추구하는
                새로운 성장 여정에 참여하는 기관들과 협력하는
                특권에 있습니다.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box related">
        <el-divider></el-divider>
        <h5 class="subtitle">관련</h5>
        <div class="grid grid-4">
          <div class="item shadow" v-for="item in related">
            <el-image :src="item.img" class="img" fit="fill"></el-image>
            <div style="flex: 1;">
              <router-link class="title" :to="item.link">
                <h4>{{ item.title }}<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h4>
              </router-link>
              <p class="text">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box box-full box-right" style="background: #eee;padding: 0 3rem;padding-bottom: 2rem;">
        <el-divider></el-divider>
        <h5 class="subtitle">블로그 참조</h5>
        <div class="grid grid-2">
          <div class="item shadow flex" v-for="item in blog" style="gap: 1rem;">
            <el-image :src="item.img" class="flex-1" fit="fill"></el-image>
            <div style="flex: 1;" class="flex-1">
              <router-link class="title" :to="item.link">
                <h4 style="text-align: start;">{{ item.title }}<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h4>
              </router-link>
              <p class="text">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer2Component />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted } from 'vue'
import useUserStore from '@/stores/user.js'
import Footer2Component from '@/components/Footer2Component.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
// ------------------------------ //
// 方法
// ------------------------------ //
// ...
// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '2-1'
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //


import { ArrowLeft, ArrowRightBold, Right } from '@element-plus/icons-vue'
import avatar1 from '@/assets/imgs/leadership/leadership-avatar-1.avif'
import avatar2 from '@/assets/imgs/leadership/leadership-avatar-2.avif'
import avatar3 from '@/assets/imgs/leadership/leadership-avatar-3.avif'
import avatar4 from '@/assets/imgs/leadership/leadership-avatar-4.avif'
import avatar5 from '@/assets/imgs/leadership/leadership-avatar-5.avif'
import avatar6 from '@/assets/imgs/leadership/leadership-avatar-6.avif'
import avatar7 from '@/assets/imgs/leadership/leadership-avatar-7.avif'
import avatar8 from '@/assets/imgs/leadership/leadership-avatar-8.avif'
import avatar9 from '@/assets/imgs/leadership/leadership-avatar-9.avif'
import avatar10 from '@/assets/imgs/leadership/leadership-avatar-10.avif'
import avatar11 from '@/assets/imgs/leadership/leadership-avatar-11.avif'
import avatar12 from '@/assets/imgs/leadership/leadership-avatar-12.avif'
import avatar13 from '@/assets/imgs/leadership/leadership-avatar-13.avif'
import avatar14 from '@/assets/imgs/leadership/leadership-avatar-14.avif'
import avatar15 from '@/assets/imgs/leadership/leadership-avatar-15.avif'
import avatar16 from '@/assets/imgs/leadership/leadership-avatar-16.avif'
import avatar17 from '@/assets/imgs/leadership/leadership-avatar-17.avif'
import avatar18 from '@/assets/imgs/leadership/leadership-avatar-18.avif'
import avatar19 from '@/assets/imgs/leadership/leadership-avatar-19.avif'
import avatar20 from '@/assets/imgs/leadership/leadership-avatar-20.avif'
import avatar21 from '@/assets/imgs/leadership/leadership-avatar-21.avif'
import avatar22 from '@/assets/imgs/leadership/leadership-avatar-22.avif'
import avatar23 from '@/assets/imgs/leadership/leadership-avatar-23.avif'
import avatar24 from '@/assets/imgs/leadership/leadership-avatar-24.avif'
import avatar25 from '@/assets/imgs/leadership/leadership-avatar-25.avif'
import avatar26 from '@/assets/imgs/leadership/leadership-avatar-26.avif'
import avatar27 from '@/assets/imgs/leadership/leadership-avatar-27.avif'
import img_1 from '@/assets/imgs/sig_thumb_1536x1536.jpg'
import { reactive } from 'vue';
import img1 from '@/assets/imgs/leadership-1.webp'
import img2 from '@/assets/imgs/leadership-2.webp'
import img3 from '@/assets/imgs/leadership-3.webp'
import img4 from '@/assets/imgs/leadership-4.webp'
import img5 from '@/assets/imgs/leadership-5.webp'
import img6 from '@/assets/imgs/leadership-6.webp'

const avatarList = [
  avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9,
  avatar10, avatar11, avatar12, avatar13, avatar14, avatar15, avatar16, avatar17, avatar18, avatar19,
  avatar9, avatar21, avatar22, avatar23, avatar24, avatar25, avatar26, avatar27
]

const members = reactive([
  { link: 'https://www.mckinsey.com/about-us/overview/our-leadership/shareholders-council', title: '글로벌 이사회', text: '이사회는 회사의 글로벌 이사회로서 회사의 전략적 방향과 정책을 결정합니다. 이사회 구성원에는 실행 파트너와 30명의 고급 파트너가 포함되어 있으며 동료들에 의해 선출되며 3년 임기를 맡고 있습니다 ' },
  // { link: 'https://www.mckinsey.com/about-us/overview/our-leadership/acceleration-team', title: '팀 과속화 ', text: '우리의 가속 팀은 글로벌 리더십 기관으로서 고객 서비스와 인력 임무를 가속화하기 위해 책임을 지고 있습니다. 이 팀은 각 지역의 리더와 핵심 역량(인력 및 다양성, 위험 및 대응 능력, 재무 등)을 연결하여 회사의 성과와 발전을 지원합니다. ' },
  { link: 'https://www.mckinsey.com/about-us/overview/our-leadership/office-leadership', title: '글로벌 오피스 & 매니지먼트 ', text: '전 세계 65개국 이상에 위치한 저희 사무실은 아시아(중국 제외), 대중국, 동유럽, 중동 및 아프리카, 유럽, 라틴 아메리카, 북아메리카의 여섯 지역을 대표하는 고위 파트너 그룹이 이끕니다. 이들 리더는 130여 개 도시에 있는 맥킨지 지역 매니저와 긴밀하게 협력하여 글로벌 기업의 최고 수준이 지역 고객과 지역사회에 전달되도록 합니다.' },
  { link: 'https://www.mckinsey.com/about-us/overview/our-leadership/practice-leadership', title: '실천 리더쉽 ', text: '우리의 업무는 대부분의 역량과 산업에 걸쳐 고객에게 서비스를 제공합니다. 각각의 글로벌 리더들은 고객에게 영향을 제공하고, 지식과 역량을 개발하고, 혁신을 일으키고, 고객을 개발할 책임이 있습니다. 우리의 고객과 이해관계자들에게 긍정적이고 지속적인 변화를 제공하기 위해 지역 리더들 및 다른 많은 사람들과 긴밀히 협력합니다.' },
])

const related = reactive([
  { link: 'https://www.mckinsey.com/about-us/overview/our-governance/client-service-policies', img: img2, title: '고객 서비스 정책', text: '업계를 선도하기 위한 야망의 일환으로 거버넌스 및 규정 준수 프로세스를 강화했습니다.' },
  { link: 'https://www.mckinsey.com/about-us/overview/our-governance/code-of-conduct', img: img1, title: '전문가 행동 강령', text: '동료와 고객을 위해 우리의 가치를 실현하는 방법을 알아보세요.' },
  { link: 'https://www.mckinsey.com/about-us/diversity/overview', img: img3, title: '다양성과 포용', text: '포용과 다양성에 대한 약속은 회사의 역사와 일상적인 관행에 내재되어 있습니다.' },
  { link: 'https://www.mckinsey.com/about-us/environmental-sustainability', img: img4, title: '환경 지속 가능성', text: '우리의 전략은 회사가 환경에 미치는 영향을 최소화하는 데 도움이 됩니다.' },
])

const blog = reactive([
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/mckinsey-announces-six-members-of-2023-external-advisory-group', img: img5, title: '맥킨지는 2023년 외부 컨설팅 그룹의 6명의 구성원 명단을 발표', text: '2023년 2월 6일 - 존경받는 리더들은 우리 회사에 다양한 전략적 주제에 대해 객관적이고 다양한 관점을 제공합니다.' },
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/whats-ahead-for-mckinsey-a-conversation-with-bob-sternfels', img: img6, title: '다음 글로벌 매니징 파트너를 소개합니다: 밥 스턴펠스', text: '2021년 7월 1일 - 캘리포니아 출신인 스턴펠스는 지난 몇 년간 맥킨지의 글로벌 고객 역량 네트워크를 이끌어 왔습니다. 1926년 창립 이래 맥킨지의 13번째 리더가 될 것입니다.' }
])
</script>

<style lang="scss" scoped>
@import url("@/assets/scss/common.scss");
@media (max-width: 480px) {
  .grid-9 {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}

.grid-9 {
  grid-template-columns: repeat(9, 1fr) !important;
}

// PC
@media screen and (min-width: 1024px) {
  #p21 {
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    overflow: auto;

    .content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .a1 {
        width: 100%;
        height: 250px;
        background: url('@/assets/image/rendered-NT1-Copy-updated-2-15-17.jpg') no-repeat center center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 42px;
        font-weight: bold;
      }

      .a2 {
        width: 100%;
        height: 266px;
        padding: 0 222px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .a3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .b1 {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          .c1 {
            width: 20%;
            height: 530px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              width: 200px;
              height: 300px;
              object-fit: contain;
            }

            p {
              height: 90px;
              padding: 0 15px;
            }
          }
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #p21 {
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    overflow: auto;

    .content {
      width: 100%;

      .a1 {
        width: 100%;
        height: 250px;
        background: url('@/assets/image/rendered-NT1-Copy-updated-2-15-17.jpg') no-repeat center center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 42px;
        font-weight: bold;
      }

      .a2 {
        width: 100%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .a3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .b1 {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          .c1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              width: 200px;
              height: 300px;
              object-fit: contain;
            }

            p {
              height: 90px;
              padding: 0 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
