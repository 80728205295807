<template>
  <div>
    <!-- <p class="titA">{{ latestUpdateTime }}</p> -->

    <table>
      <thead>
        <tr>
          <th>N</th>

          <th>등락률</th>
          <th>종목명</th>
          <th>현재가</th>
          <th>전일비</th>
          <th>등락률</th>
          <th>시가</th>
          <th>고가</th>
          <th>저가</th>
          <th>거래량</th>
          <th>PER</th>
          <th>ROE</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in exchangeData" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.changeRate }}</td>
          <td>{{ item.stockName }}</td>
          <td>{{ item.currentPrice }}</td>
          <td>{{ item.changeAmount }}</td>

          <td
            :style="{
              color: item.changeRate1 === 0 ? 'black' : item.changeRate1 < 0 ? 'blue' : 'red'
            }"
          >
            {{ item.changeRate1 }}%
          </td>
          <td>{{ item.openPrice }}</td>
          <td>{{ item.highPrice }}</td>
          <td>{{ item.lowPrice }}</td>
          <td>{{ item.volume }}</td>
          <td>{{ item.per }}</td>
          <td>{{ item.roe }}</td>

          <!-- <td>{{ item.id }}</td>
            <td>{{ item.changeRate }}</td>
            <td>{{ item.stockName }}</td>
            <td>{{ item.currentPrice }}</td>
            <td>{{ item.changeAmount }}</td>
            <td>{{ item.volume }}</td>

            <td>{{ item.openPrice}}</td>
            <td>{{ item.highPrice}}</td>
            <td>{{ item.lowPrice}}</td>

            <td>{{ item.volume }}</td>
            <td>{{ item.per }}</td>
            <td>{{ item.roe }}</td>
             -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExchangeData',
  data() {
    return {
      exchangeData: [],
      latestUpdateTime: ''
    }
  },
  mounted() {
    this.fetchExchangeData()
  },
  methods: {
    async fetchExchangeData() {
      try {
        const response = await axios.get('https://sj-investing.com/ideal/api/siseLowUp/left')

        if (response.data && response.data.code === 200) {
          this.exchangeData = response.data.data
          this.latestUpdateTime = response.data.data[0].createTime

          //   console.log('Data type:', typeof response.data.data);
          // console.log('Data content:', response.data.data);
        } else {
          console.error('Invalid response from the API:', response.data)
        }
      } catch (error) {
        console.error('Error fetching exchange data:', error)
      }
    }
  }
}
</script>

<style scoped>
.custom-line {
  border: none;
  height: 2px; /* 横线高度 */
  background-color: #333; /* 横线颜色 */
  /* margin: 20px auto; */
  width: 95%;
}
.titA {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
}

th,
td {
  border: 1px solid #ddd;
  /* padding: 8px; */
  text-align: left;
  text-align: center;
}

thead {
  background-color: #f4f4f4;
}
</style>
