<template>
  <div id="p45sp14">
    <HeaderComponent />
    <div class="a1">
      <!-- 按钮组 -->
      <div class="button-group">
        <button @click="activeTab = 'tab1'" :class="{ active: activeTab === 'tab1' }">국내</button>
        <button @click="activeTab = 'tab2'" :class="{ active: activeTab === 'tab2' }">해외</button>
      </div>
      <br />
      <!-- 内容区域 -->
      <div class="content">
        <div v-show="activeTab === 'tab1'" class="combined-tradingview">
          <!-- Tab 1 内容 -->
          <PaD14L />
          <!-- Tab 1 内容 -->
        </div>
        <div v-show="activeTab === 'tab2'">
          <!-- Tab 2 内容 -->
          <PaD14R />
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import PaD14L from './PaD14L.vue'
import PaD14R from './PaD14R.vue'
import useUserStore from '@/stores/user.js'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
export default {
  data() {
    return {
      activeTab: 'tab1' // 默认显示第一个标签页的内容
    }
  },
  components: {
    FooterComponent,
    HeaderComponent,
    PaD14L,
    PaD14R
  },
  mounted() {
    const userStore = useUserStore()
    userStore.activeIndex = '4-5'
  }
}
</script>

<style scoped>
#p45sp14 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .a1 {
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    overflow: auto;

    .button-group {
      display: flex;
      justify-content: center;
      /* margin: 0 auto;  */
    }

    .button-group button {
      padding: 10px 400px;
      /* border: none;  */
      background-color: #ffffff;
      cursor: pointer;
      color: rgb(221, 221, 221);

      transition:
        color 0.3s,
        border-color 0.3s;
      border: 2px solid rgb(235, 235, 235);
      border-color: rgb(235, 235, 235);
    }

    .button-group button:first-child {
      border-radius: 13px 0 0 13px;
    }

    .button-group button:last-child {
      border-radius: 0 13px 13px 0;
    }

    .button-group button.active {
      color: rgb(0, 0, 0);
      border-color: black;
    }

    .combined-tradingview {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 500px;
    }
    .combined-tradingview1 {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 500px;
    }

    .widget-container {
      width: 100%;
      height: 100%;
    }
    .screener-container {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 1700px) {
      .button-group button {
        padding: 10px 80px;
      }
    }
  }
}
</style>
