<template>
  <div>
    <!-- <p class="titA">{{ latestUpdateTime }}</p> -->

    <table>
      <thead>
        <tr>
          <th @click="sortTable('industryName')">업종명</th>
          <th @click="sortTable('indexId')">지수</th>
          <th @click="sortTable('changeAmount')">전일비</th>
          <th @click="sortTable('changeRate')">등락률(%)</th>
          <th @click="sortTable('turnoverMillion')">거래대금(백만)</th>
          <th @click="sortTable('rise')">상승</th>
          <th @click="sortTable('flat')">보합</th>
          <th @click="sortTable('fall')">하락</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in exchangeData" :key="item.id">
          <!-- <td>{{ item.id }}</td> -->
          <td>{{ item.industryName }}</td>
          <td>{{ item.indexId }}</td>
          <td
            :style="{
              color: item.changeAmount === 0 ? 'black' : item.changeAmount < 0 ? 'blue' : 'red'
            }"
          >
            {{ item.changeAmount }}
          </td>
          <td
            :style="{
              color: item.changeRate === 0 ? 'black' : item.changeRate < 0 ? 'blue' : 'red'
            }"
          >
            {{ item.changeRate }}%
          </td>

          <td>{{ item.turnoverMillion }}</td>
          <td>{{ item.rise }}</td>
          <td>{{ item.flat }}</td>
          <td>{{ item.fall }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
//p12
export default {
  name: 'ExchangeData',
  data() {
    return {
      exchangeData: [],
      latestUpdateTime: '',
      currentSortField: '', // 当前排序字段
      currentSortAsc: true // 当前排序顺序
    }
  },
  mounted() {
    this.fetchExchangeData()
  },
  methods: {
    async fetchExchangeData() {
      try {
        const response = await axios.post('https://sj-investing.com/ideal/api/industry/left', {
          order: this.currentSortField,
          asc: this.currentSortAsc
        })

        if (response.data && response.data.code === 200) {
          this.exchangeData = response.data.data
          this.latestUpdateTime =
            response.data.data.length > 0 ? response.data.data[0].createTime : ''
        } else {
          console.error('Invalid response from the API:', response.data)
        }
      } catch (error) {
        console.error('Error fetching exchange data:', error)
      }
    },
    sortTable(field) {
      if (this.currentSortField === field) {
        this.currentSortAsc = !this.currentSortAsc // 如果是同一个字段，切换排序顺序
      } else {
        this.currentSortField = field
        this.currentSortAsc = true // 默认设置为升序
      }
      this.fetchExchangeData() // 重新获取排序后的数据
    }
  }
}
</script>

<style scoped>
.custom-line {
  border: none;
  height: 2px; /* 横线高度 */
  background-color: #333; /* 横线颜色 */
  /* margin: 20px auto; */
  width: 95%;
}
.titA {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
}
th {
  cursor: pointer;
}
th,
td {
  border: 1px solid #ddd;
  /* padding: 8px; */
  text-align: left;
  text-align: center;
}

thead {
  background-color: #f4f4f4;
}
</style>
