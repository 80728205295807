<template>





    <br>
    
      <div>
          <!-- 按钮组 -->
          <div class="button-group">
            <button @click="activeTab = 'tab1'" :class="{ active: activeTab === 'tab1' }">일일 데이터</button>
            <button @click="activeTab = 'tab2'" :class="{ active: activeTab === 'tab2' }">기간 데이터</button>
         </div>
      <br>
          <!-- 内容区域 -->
          <div class="content">
            <div v-show="activeTab === 'tab1'" class="combined-tradingview">
              <!-- Tab 1 内容 -->
              <PaD13L/>
              <!-- Tab 1 内容 -->
            </div>
      
      
      
            <div v-show="activeTab === 'tab2'">
              <!-- Tab 2 内容 -->
              <PaD13R/>
            </div>
            
          </div>
        </div>
      
      
      
      
        
      
         
        </template>
        
        <script>
       
    
        import  PaD13L from './PaD13L.vue';
        import PaD13R from './PaD13R.vue';
       
        export default {
          data() {
          return {
            activeTab: 'tab1',  // 默认显示第一个标签页的内容
          };
        },
          components: {
         
            PaD13L,
            PaD13R
        },
        
          
        };
        </script>
        
        <style scoped>
      
      .button-group {
        display: flex;
        justify-content: center; 
        /* margin: 0 auto;  */
        
      }
    
    
      .button-group button {
        padding: 10px 50px;
        border: none; 
        background-color: #dad9d9;
        cursor: pointer;
        color: grey; 
     
        transition: all 0.3s;
       
      }
      
      .button-group button:first-child {
        border-radius: 13px 0 0 13px; 
      }
    
      .button-group button:last-child {
        border-radius: 0 13px 13px 0; 
      }
    
    
    
    
      .button-group button.active {
        background-color: #000000; /* 选中按钮的背景颜色保持不变 */
        color: rgb(255, 255, 255); /* 选中按钮的文字颜色变为黑色 */
        
      }
      
    
      
        .combined-tradingview {
          position: relative;
          display: flex;
          flex-direction: column;
          height: 500px;
        }
        .combined-tradingview1 {
          position: relative;
          display: flex;
          flex-direction: column;
          height: 500px;
        }
        
        .widget-container {
          width: 100%;
          height: 100%; 
        }
        .screener-container{
          width: 100%;
          height: 100%; 
        }
      
      
      
     
      
      
    
      
        </style>
        