<template>
  <!-- 全局音频 -->
  <audio src="#" id="playAudio"></audio>
  <!-- 路由出口：路由匹配到的组件将渲染在这里 -->
  <RouterView />
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router';

const route = useRoute()
function OnChangeResize() {
  // const screenWidth = window.innerWidth
  // const screenHeight = window.innerHeight;
}
onMounted(() => {
  window.addEventListener('resize', OnChangeResize)
  console.log(route);
})
onUnmounted(() => {
  window.removeEventListener('resize', OnChangeResize)
})
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
}
</style>
