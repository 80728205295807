<template>
  <div id="home">
    <HeaderComponent />
    <div class="page overview">
      <div class="box box-full" style="width: 100%;margin-top: 0;height: 100vh;">
        <video class="p-video" autoplay muted loop>
          <source :src="videoData.url.ogg" type="video/ogg" />
          <source :src="videoData.url.webm" type="video/webm" />
          <source :src="videoData.url.mp4" type="video/mp4" />
        </video>
        <!-- 描述 -->
        <div class="video-desc flex column" style="gap: 1rem;">
          <h1 class="p-title">{{ videoData.info.title }}</h1>
          <span class="p-desc">{{ videoData.info.desc }}</span>
        </div>
      </div>

      <div class="box box-full" style="padding: 4rem 8rem; background: #051c2c;">
        <div class="flex" style="gap: 2rem;align-items: center;">
          <div class="info flex column flex-1" style="gap: 1rem;color: #fefefe;">
            <h1 class="title">지속 가능하고 포용적인 성장을 실현하는 방법은 무엇인가요?</h1>
            <p class="text">이것은 많은 것을 필요로 합니다. 헌신과 열정 용기와 호기심 하드 스킬과 소프트 스킬
              어떤 것은 타고난 것이고, 어떤 것은 경험이나 배우기를 통해 얻을 수 있습니다.
              하지만 한 가지는 확실합니다.
              영향력을 발휘하려면 동기 부여와 배우고 싶은 열망을 가진 뛰어난 인재가 필요합니다.</p>
            <p class="text">맥켄지에 합류하면 기술과 혁신을 통해 기관과 산업을 재구성하는 데 귀하의 작업이 도움이 됩니다. 고객을 직접 대상으로 하든 말든, 귀하는 이 시대 가장 중요한 일부
              업무에 참여하게 될 것입니다. 회사의 모든 구성원은 당사의 성공에 필수적입니다.</p>
          </div>
          <el-image :src="Img1" class="flex-1"></el-image>
        </div>
      </div>

      <div class="box box-full" style="padding: 4rem 8rem;">
        <div class="flex" style="gap: 2rem;align-items: center;">
          <el-image :src="Img2" class="flex-1"></el-image>
          <div class="info flex column flex-1" style="gap: 1rem;">
            <h1 class="title" style="color: #2251ff;">탁월함은 어디에서나 올 수 있습니다.</h1>
            <p class="text">맥킨지에서는 능력 있는 사람을 채용하며, 학위는 중요하지 않습니다. 뛰어난 인재는 특정한 배경으로 제한되지 않으며, 어디에서든지 훌륭한 사람들이 있을 수 있다는 것을
              알고 있습니다. 맥킨지는 당신의 잠재력을 믿으며, 당신의 배경에 관계없이 기회를 제공합니다.</p>
            <p class="text">맥킨시의 커리어는 다양한 경로를 통해 시작될 수 있습니다. 대부분의 경우 전통적인 방식이 아닐 수도 있습니다. 업무를 통해 전문성을 쌓았든, 인턴십을 통해 새로운
              기술을 익혔든, 혹은 MBA 학위를 취득했든, 모든 경험은 중요합니다. 맥킨시는 갓 사회에 진출한 신입부터 수십 년간의 경력을 가진 베테랑까지 다양한 인재를 채용합니다. 맥킨시에는 큰 꿈과
              넓은 시야를 가진, 다양한 삶의 경험을 가진, 새로운 도전을 두려워하지 않는 인재가 필요합니다.</p>
            <router-link class="see" style="color: #333" to="https://www.mckinsey.com/careers/search-jobs">
              <h6>신청 준비가 되셨나요 ? 우리의 채용 공고를 검색해 보세요<el-icon color="#2251ff">
                  <Right />
                </el-icon></h6>
            </router-link>
            <router-link class="see" style="color: #333" to="https://www.mckinsey.com/careers/interviewing">
              <h6>면접에 대해 더 알아보기<el-icon color="#2251ff">
                  <Right />
                </el-icon></h6>
            </router-link>
            <router-link class="see" style="color: #333" to="https://www.mckinsey.com/careers/explore">
              <h6>여기서 일하는 것이 어떤지 알아보세요.<el-icon color="#2251ff">
                  <Right />
                </el-icon></h6>
            </router-link>
          </div>

        </div>
      </div>

      <div class="box box-full">
        <div class="flex column" style="padding: 5rem 10rem;background-color: #2251ff;color: #fefefe;">
          <h2 class="title" style="margin-bottom: 1rem">회사의 영향력은 매일 변화하며, 이러한 비전 또한 주변 세계의 변화에 따라 매년 진화합니다. 어제는 고객을 위한
            제품을 디자인했고, 오늘은 5년 발전 전략을 수립할 수도 있습니다. 업무의 다양성은 무궁무진합니다.</h2>
          <span class="author" style="font-size: 0.8rem;">— Anvay</span>
          <span class="author" style="margin-left: 1rem;font-size: 0.8rem;">Senior associate</span>
        </div>
      </div>

      <div class="box">
        <div class="flex" style="gap: 1rem;align-items: center;">
          <el-image :src="Img3" class="flex-1"></el-image>
          <div class="info flex column flex-1" style="align-items: center;gap: 1rem">
            <h2 class="title" style="color: #2251ff;">우리는 여러분이 질문을 가지고 있다는 것을 알고 있습니다... 우리는 특히 어려운 질문을 좋아합니다.</h2>
            <p class="text">맥킨지의 동료들이 맥킨지에서 일하는 경험에 대해 진솔하게 이야기합니다. 잠시 시간을 내서 그들이 무엇을 말하는지 들어보세요. 놀랄 수도 있습니다. 맥킨지는 진솔한
              이야기를 나누고 어려운 질문에 답하는 것을 좋아하는 사람들로 구성된 회사입니다.만약 맥킨지 직원을 만나게 된다면, 궁금한 점이 있다면 언제든지 질문해보세요!</p>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="grid grid-4">
          <div class="flex column" style="gap: 1rem;" v-for="item in question">
            <el-image :src="item.cover"></el-image>
            <h3 class="title">{{ item.title }}</h3>
          </div>
        </div>
      </div>

      <div class="box box-full" style="padding: 4rem 8rem; background: #051c2c;">
        <div class="grid grid-2" style="color: #fefefe;">
          <div class="info flex column flex-1" style="gap: 1rem;">
            <h1 class="title" style="color: #fefefe;">공동으로 만들어가는 중요한 유산</h1>
            <el-image :src="Img8"></el-image>
            <h4 class="subtitle" style="margin-bottom: 0">우리는 고객의 영향력 있는 파트너입니다.</h4>
            <p class="text" style="margin-top: 0;">우리는 고객의 발전 잠재력을 끊임없이 믿어왔습니다. 지금, 우리는 지속 가능한 발전, 포용성, 경제 성장을 동시에 추구하는
              새로운 발전 여정을 떠나는 조직들과 협력하게 되어 영광입니다.</p>
            <router-link class="see" style="color: #fefefe"
              to="https://www.mckinsey.com/about-us/overview/sustainable-and-inclusive-growth">
              <h6>자세히 알아보기<el-icon color="#fefefe">
                  <Right />
                </el-icon></h6>
            </router-link>
          </div>
          <div class="info flex column flex-1" style="gap: 1rem;">
            <el-image :src="Img9"></el-image>
            <h2 class="subtitle" style="margin-bottom: 0">2022 ESG Report 2022년 ESG 보고</h2>
            <p class="text" style="margin-top: 0;">우리 기업의 2022 ESG 보고서, "모두를 위한 지속 가능하고 포용적인 성장 미래 만들기"는 지속적이고 긍정적인 변화를
              일으키기 위한 우리의 가시적인 진전을 보여줍니다. 이 보고서는 사례 연구와 통계 자료를 통해 우리 회사 직원들이 탈탄소화 촉진, 포용적 성장 견인, 책임과 컴플라이언스 기준 설정 등에 어떻게
              기여하고 있는지 상세히 설명합니다.</p>
            <router-link class="see" style="color: #fefefe" to="https://www.mckinsey.com/esg-report">
              <h6>자세히 알아보기<el-icon color="#fefefe">
                  <Right />
                </el-icon></h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="box" style="width: 100%;">
        <h5 class="subtitle">자세히 알아보기</h5>
        <el-collapse>
          <el-collapse-item :name="index" v-for="(item, index) in infoList">
            <template #title>
              <h1 class="title">{{ item.title }}</h1>
            </template>
            <div class="text">{{ item.text }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <Footer2Component />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted, reactive } from 'vue'
import useUserStore from '@/stores/user.js'
import Footer2Component from '@/components/Footer2Component.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import router from '@/router/index.js'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
// ------------------------------ //
// 方法
// ------------------------------ //
function to() {
  window.location.href = 'https://www.vcnmgjk.com'
}
// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '2-2'
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //

import { ArrowRight, Right } from '@element-plus/icons-vue'
import video from '@/assets/video/index-1.mp4'
import Img1 from '@/assets/imgs/index/index-1.jpg'
import Img2 from '@/assets/imgs/index/index-2.jpg'
import Img3 from '@/assets/imgs/index/index-3.jpg'
import Img4 from '@/assets/imgs/index/index-4.jpg'
import Img5 from '@/assets/imgs/index/index-5.jpg'
import Img6 from '@/assets/imgs/index/index-6.jpg'
import Img7 from '@/assets/imgs/index/index-7.jpg'
import Img8 from '@/assets/imgs/index/index-8.jpg'
import Img9 from '@/assets/imgs/index/index-9.jpg'
//视频数据
const videoData = reactive({
  url: {
    mp4: video
  },
  info: {
    title: '올바른 장소에 오셨습니다.',
    desc: '도전과 영감을 선사하는 흥미진진한 직업을 찾고 계신가요?맥킨지의 면접 프로세스에 대한 안내를 원하시나요?혹은 컨설팅이란 무엇인지, 맥킨지에서 일하는 것이 어떤 것인지 궁금하신가요?어떤 이유든, 올바른 장소에 오셨습니다.'
  }
});

const question = reactive([
  { cover: Img4, title: '무엇을 고민하고 있는거에요 ?' },
  { cover: Img5, title: '맥켄지에서 어떤 신화를 세우고 싶은거에요 ?' },
  { cover: Img6, title: '맥켄지는 어떤 인재를 채용하죠 ?' },
  { cover: Img7, title: '후보자로서 어떤 의견이 있으신가요 ?' },
])

const infoList = reactive([
  { title: '평등한 고용 기회 선언', text: '맥킨지앤드컴퍼니는 기회 균등 고용주입니다. 우리는 모든 개인을 지원, 고무, 존중하는 업무 환경을 조성하고 인종, 피부색, 종교, 성별, 성적 지향, 성 정체성, 결혼 여부, 연령, 장애, 출신 국가 또는 민족, 병역 여부, 시민권 또는 기타 보호되는 특성에 따른 차별 없이 능력에 기반하여 인사 절차를 적용하는 데 전념하고 있습니다.' },
  { title: '미국 연방 보험 투명 규제', text: '미국의 맥킨지 건강 보험은 연방 보험 적용 투명성 규칙을 준수합니다. 연방 보장 범위 투명성 규칙에 따라 기계 판독 가능 파일(MRF)이 제공되며, 여기에는 건강보험 플랜과 의료 서비스 제공자 간의 협상된 서비스 요금 및 네트워크 외 허용 금액이 포함됩니다. 기계 판독 가능 파일은 연구자, 규제 기관 및 애플리케이션 개발자가 데이터에 더 쉽게 액세스하고 분석할 수 있도록 포맷되어 있습니다.' },
  { title: '채용 과정에서의 장애인 편의', text: 'McKinsey는 관심 있고 자격을 갖춘 모든 지원자를 채용 기회에 지원할 수 있도록 초대합니다. 사이트를 이용하거나 액세스하는 데 장애가 있거나 장애로 인해 지원 과정에서 다른 편의가 필요한 경우 합리적인 편의를 요청할 수 있습니다. 요청을 하려면 채용팀에 문의하시면 기꺼이 도와드리겠습니다. 자세한 내용은 장애인이 McKinsey를 탐색하는 방법에 대한 자주 묻는 질문을 참조하세요.' },
  { title: '중요 사기 신고', text: '맥킨지는 맥킨지 채용에 대한 허위 제안과 관련된 사기를 인지하게 되었습니다. 이러한 사기와 허위 제안은 가짜 웹사이트, 이메일 주소, 문자 메시지를 사용합니다. 이러한 제안은 합법적인 것이 아니며, McKinsey의 채용 프로세스에는 인스턴트 메시지를 통한 면접이 포함되지 않으며, 후보자에게 제품이나 서비스를 구매하거나 당사를 대신하여 결제를 처리하도록 요구하지 않습니다.' },
  { title: '외부 헤드헌터 회사', text: 'McKinsey는 채용 및 인재 유치 활동을 위해 소수의 선호 서비스 제공업체(외부 서치펌)와 협력하고 있습니다. 이러한 공급업체는 평가 프로세스를 거쳐 일련의 표준을 충족하고 당사의 가치에 부합하는지 확인합니다. 현재 맥킨지와 구체적인 계약 또는 서치펌 계약을 체결하지 않은 공급업체는 회사를 대신하여 후보자에게 연락하거나 맥킨지 동료에게 후보자를 소개할 권한이 없습니다. 또한 채용 제안을 받은 경우에도 알선 수수료를 받을 자격이 없습니다.' }
])

</script>

<style lang="scss" scoped>
@import url("@/assets/scss/common.scss");

.page {
  background: #fff;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.video-desc {
  position: absolute;
  width: 80vw;
  left: 10vw;
  bottom: 3rem;
  color: #FFF;

  .p-title {
    font-size: 52px;
    line-height: 1.15em;
  }

  .p-desc {
    display: block;
    font-size: 20px;
    line-height: 1.6em;
  }

  .learn-more {
    margin-top: 16px;

    &:hover {
      .icon {
        transform: translateX(16px);
      }
    }

    .icon {
      margin-left: 20px;
      transition: all 0.4s;
    }
  }
}

.el-collapse-item {
  padding: 1rem 0 0 0;

  :deep(.el-collapse-item__header) {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 1440px) {
  article {
    width: 80vw;
  }

  .article-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  article {
    width: 80vw;
  }

  .article-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .research-themes-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .people-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .avatar {
    height: 6rem !important;
  }

  .people {
    flex-direction: row !important;
  }

  .video-container {
    &.p-video-container {
      height: unset;
    }

    .p-video {
      height: 814px;
    }

    .video-desc {
      width: 90%;
      left: 5%;
    }
  }

  article {
    width: 90vw;
  }

  .article-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .research-themes-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .people-list {
    grid-template-columns: repeat(1, 1fr);

    .people-item {
      .cover {
        width: 65px;
        height: 65px;
      }
    }
  }

  .article-box {
    flex-direction: column;

    .cover {
      width: 100%;
      margin-right: 0;
    }

    .info-box {
      .p-title {
        font-size: 28px;
      }
    }
  }

  .news-list {
    grid-template-columns: repeat(1, 1fr);

    .p-news-item {
      width: 100%;

      +.p-news-item {
        margin-top: 36px;
      }
    }
  }
}

// PC
@media screen and (min-width: 1024px) {
  #home {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    #r_center_log,
    #r_center_log:hover {
      border: 2px solid rgb(255, 215, 0);
      border-radius: 10px;
      position: fixed;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 118px;
      right: 2%;
      top: 50%;
      margin-top: -50px;
      cursor: pointer;

      @keyframes animate {
        0% {
          background-position: 0 0;
        }

        50% {
          background-position: 300% 0;
        }

        100% {
          background-position: 0 0;
        }
      }

      #r_center_log_img {
        width: 44px;
        height: 44px;
        border-radius: 10px;
      }

      @keyframes san {

        0%,
        15%,
        50%,
        52%,
        70%,
        90%,
        99.1% {
          color: rgb(255, 215, 0);
          filter: blur(1px);
        }

        12%,
        15.1%,
        60%,
        70.1%,
        90.5%,
        100% {
          color: rgb(255, 255, 255);
          text-shadow:
            0 0 5px rgb(22, 138, 216),
            0 0 25px rgb(22, 138, 216),
            0 0 35px rgb(22, 138, 216),
            0 0 105px rgb(22, 138, 216),
            0 0 155px rgb(22, 138, 216);
          filter: blur(0px);
        }
      }

      .r_center_log_a {
        position: relative;
        top: 18px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        font-weight: 800;
        text-align: center;
        font-size: 20px;
        color: rgb(4, 15, 36);
        animation: san 6s linear infinite;
      }

      #r_center_log_a:after {
        filter: blur(20px);
      }

      a:link {
        text-decoration: none;
      }

      a:visited {
        text-decoration: none;
      }

      a:hover {
        text-decoration: none;
      }

      a:active {
        text-decoration: none;
      }

      a:focus {
        text-decoration: none;
      }
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/web-pic.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/rendered-3-3-17.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a3 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/pic012524.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      height: 600px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .b1 {
        width: 32.5%;
        height: 100%;
        padding: 35px 30px;
        background-color: white;

        .c1 {
          font-size: 24px;
          font-weight: bold;
          color: #313131;
        }

        .c2 {
          display: block;
          width: 50px;
          height: 5px;
          margin: 10px 0;
          background-color: #daac76;
        }

        .c3 {
          width: 100%;

          .d1 {
            width: 100%;
            height: 110px;
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f4f4f4;

            img {
              width: 130px;
              height: 100%;
              margin-right: 10px;
            }

            a {
              flex-grow: 1;
              font-size: 14px;
              font-weight: bold;
            }
          }
        }

        .c4 {
          width: 100%;
          height: 50px;
          font-weight: bold;
          background-color: #daac76;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .c5 {
          width: 100%;
          margin-top: 15px;

          .d1 {
            width: 100%;
            height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            border-top: 1px solid #000000;
            font-weight: bold;
          }
        }
      }
    }

    .a2 {
      width: 100%;
      height: 550px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .b1 {
        width: 49.4%;
        height: 100%;
        background-color: white;
        padding: 38px 85px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .c1 {
          width: 100%;
          height: 50px;
          font-size: 30px;
          font-weight: bold;
          color: #313131;
          display: flex;
          align-items: center;
        }

        .c2 {
          display: block;
          width: 50px;
          height: 5px;
          margin: 10px 0;
          background-color: #daac76;
        }

        .c3 {
          width: 100%;
          margin: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .c4 {
          width: 185px;
          height: 50px;
          font-weight: bold;
          background-color: #daac76;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #home {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    #r_center_log,
    #r_center_log:hover {
      border: 2px solid rgb(255, 215, 0);
      border-radius: 10px;
      position: fixed;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 118px;
      right: 2%;
      top: 50%;
      margin-top: -50px;
      cursor: pointer;

      @keyframes animate {
        0% {
          background-position: 0 0;
        }

        50% {
          background-position: 300% 0;
        }

        100% {
          background-position: 0 0;
        }
      }

      #r_center_log_img {
        width: 44px;
        height: 44px;
        border-radius: 10px;
      }

      @keyframes san {

        0%,
        15%,
        50%,
        52%,
        70%,
        90%,
        99.1% {
          color: rgb(255, 215, 0);
          filter: blur(1px);
        }

        12%,
        15.1%,
        60%,
        70.1%,
        90.5%,
        100% {
          color: rgb(255, 255, 255);
          text-shadow:
            0 0 5px rgb(22, 138, 216),
            0 0 25px rgb(22, 138, 216),
            0 0 35px rgb(22, 138, 216),
            0 0 105px rgb(22, 138, 216),
            0 0 155px rgb(22, 138, 216);
          filter: blur(0px);
        }
      }

      .r_center_log_a {
        position: relative;
        top: 18px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        font-weight: 800;
        text-align: center;
        font-size: 20px;
        color: rgb(4, 15, 36);
        animation: san 6s linear infinite;
      }

      #r_center_log_a:after {
        filter: blur(20px);
      }

      a:link {
        text-decoration: none;
      }

      a:visited {
        text-decoration: none;
      }

      a:hover {
        text-decoration: none;
      }

      a:active {
        text-decoration: none;
      }

      a:focus {
        text-decoration: none;
      }
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 100%;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/web-pic.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 24px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 18px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/rendered-3-3-17.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 24px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a3 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/pic012524.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 24px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .b1 {
        width: 100%;
        padding: 15px 15px;
        background-color: white;

        .c1 {
          font-size: 24px;
          font-weight: bold;
          color: #313131;
        }

        .c2 {
          display: block;
          width: 50px;
          height: 5px;
          margin: 10px 0;
          background-color: #daac76;
        }

        .c3 {
          width: 100%;

          .d1 {
            width: 100%;
            height: 110px;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f4f4f4;

            img {
              width: 130px;
              height: 100%;
              margin-right: 10px;
            }

            a {
              flex-grow: 1;
              font-size: 14px;
              font-weight: bold;
            }
          }
        }

        .c4 {
          width: 100%;
          height: 50px;
          font-weight: bold;
          background-color: #daac76;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .c5 {
          width: 100%;
          margin-top: 15px;

          .d1 {
            width: 100%;
            height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            border-top: 1px solid #000000;
            font-weight: bold;
          }
        }
      }
    }

    .a2 {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .b1 {
        width: 100%;
        background-color: white;
        padding: 38px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .c1 {
          width: 100%;
          height: 50px;
          font-size: 30px;
          font-weight: bold;
          color: #313131;
          display: flex;
          align-items: center;
        }

        .c2 {
          display: block;
          width: 50px;
          height: 5px;
          margin: 10px 0;
          background-color: #daac76;
        }

        .c3 {
          width: 100%;
          margin: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .c4 {
          width: 185px;
          height: 50px;
          font-weight: bold;
          background-color: #daac76;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
