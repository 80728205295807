<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>순매도</th>
        </tr>
      </thead>
    </table>

    <table>
      <thead>
        <tr>
          <th>종목명</th>
          <th>
            수량(주)
            <span class="sort-button" @click="sortTable('quantityShares')">
              {{
                sortStatus.quantityShares === 'none'
                  ? '&#8597;'
                  : sortStatus.quantityShares === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            금액(억)
            <span class="sort-button" @click="sortTable('amountBillion')">
              {{
                sortStatus.amountBillion === 'none'
                  ? '&#8597;'
                  : sortStatus.amountBillion === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            등락률(%)
            <span class="sort-button" @click="sortTable('changeRate')">
              {{
                sortStatus.changeRate === 'none'
                  ? '&#8597;'
                  : sortStatus.changeRate === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
          <th>
            보유율(%)
            <span class="sort-button" @click="sortTable('holdingRate')">
              {{
                sortStatus.holdingRate === 'none'
                  ? '&#8597;'
                  : sortStatus.holdingRate === 'asc'
                    ? '&#8595;'
                    : '&#8593;'
              }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in exchangeData" :key="item.id">
          <td :style="{ borderLeft: item.changeRate < 0 ? '4px solid blue' : '4px solid red' }">
            {{ item.stockName }}
          </td>
          <td>{{ item.quantityShares }}</td>
          <td>{{ item.amountBillion }}</td>
          <td :style="{ color: item.changeRate < 0 ? 'blue' : 'red' }">{{ item.changeRate }}%</td>
          <td>{{ item.holdingRate }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExchangeData',
  data() {
    return {
      exchangeData: [],
      latestUpdateTime: '',
      currentSortField: '', // 当前排序字段
      currentSortAsc: true, // 当前排序顺序
      sortStatus: {
        quantityShares: 'none',
        amountBillion: 'none',
        changeRate: 'none',
        holdingRate: 'none'
      }
    }
  },
  mounted() {
    this.fetchExchangeData()
  },
  methods: {
    async fetchExchangeData() {
      try {
        const response = await axios.post(
          'https://sj-investing.com/ideal/api/foreignerTrading/left/netBuy1',
          {
            order: this.currentSortField,
            asc: this.currentSortAsc
          }
        )

        if (response.data && response.data.code === 200) {
          this.exchangeData = response.data.data
          this.latestUpdateTime =
            response.data.data.length > 0 ? response.data.data[0].createTime : ''
        } else {
          console.error('Invalid response from the API:', response.data)
        }
      } catch (error) {
        console.error('Error fetching exchange data:', error)
      }
    },
    sortTable(field) {
      if (this.currentSortField === field) {
        this.currentSortAsc = !this.currentSortAsc
        this.sortStatus[field] = this.currentSortAsc ? 'asc' : 'desc'
      } else {
        this.currentSortField = field
        this.currentSortAsc = true
        this.sortStatus[field] = 'asc'
      }
      for (let key in this.sortStatus) {
        if (key !== field) {
          this.sortStatus[key] = 'none'
        }
      }
      this.fetchExchangeData()
    }
  }
}
</script>

<style scoped>
.custom-line {
  border: none;
  height: 2px; /* 横线高度 */
  background-color: #333; /* 横线颜色 */
  /* margin: 20px auto; */
  width: 95%;
}
.titA {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
}

th,
td {
  border: 1px solid #ddd;
  /* padding: 8px; */
  text-align: left;
  text-align: center;
}

thead {
  background-color: #f4f4f4;
}
.sort-button {
  cursor: pointer;
  margin-left: 5px; /* 或适当的间距 */
  /* opacity: 0; */
  transition: opacity 0.3s;
}

/* th:hover .sort-button {
    opacity: 1; 
    
  } */
</style>
