<template>
  <div id="p23">
    <HeaderComponent />
    <div class="overview">
      <el-carousel :interval="5000" arrow="always" hight="auto">
        <el-carousel-item v-for="item in carousel" :key="item" class="carousel-item">
          <div style="position: relative;height: 100%;">
            <el-image :src="item.img" class="img" fit="cover" style="height: 100%;width: 100%;"></el-image>
            <div class="mck"></div>
            <div class="info">
              <span class="tip">{{ item.desc }}</span>
              <h1 class="title">{{ item.title }}</h1>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="page">
        <div class="box introduce">
          <h2 class="title">우리는 기업, 사회 그리고 회사 내부에서 다양성, 공정성, 포용성을 장기적으로 추진합니다..</h2>
          <div class="grid grid-2">
            <div class="text">맥킨지에서 다양성, 공정성 및 포용성은 단순히 윤리적 요구 사항이 아닌, 고객들에게 실질적이고 지속적인 성과 향상을 도와주고, 뛰어난 인재들을 유치, 육성, 동기
              부여하고 유지할 수 있는 회사를 구축하는 데 필수적인 핵심 가치입니다.</div>
            <div class="text">혁신적인 연구를 통해 우리는 경제적 다양성을 위한 설득력 있는 비즈니스 및 경제적 논리를 제시합니다.
              우리는 글로벌 영향력을 활용하여 통찰력을 공유하고, 협력을 위한 파트너십을 구축하며, 고객에게 서비스를 제공하며, 핵심 의사결정자들에게 정보를 제공함으로써 진정한 변화를 이끌어낼 수 있도록
              돕습니다.</div>
          </div>
          <div class="grid grid-3">
            <div class="info">
              <h1>140+</h1>
              <h3>시민권</h3>
              <span class="text center">사용하는 언어는 135종, 사무실 위치는 65개 국가 이상</span>
            </div>
            <div class="info">
              <h1>$20M</h1>
              <h3>투자</h3>
              <span class="text center">다양성, 공정성, 포용성 연구 분야</span>
            </div>
            <div class="info">
              <h1>100%</h1>
              <h3>성적</h3>
              <span class="text center">2006년부터 매년 인권캠페인 재단 기업 평등 지수(Corporate Equality Index)에서 LGBTQ 평등을 위해 활동하고
                있습니다.</span>
            </div>
          </div>
        </div>
        <div class="box equity">
          <h5 class="subtitle center">인종 평등을 향한 우리의 여정</h5>
          <div class="item">
            <el-image :src="img4" class="img" fit="fill"></el-image>
            <div style="flex: 1;">
              <router-link class="title" to="https://www.mckinsey.com/about-us/diversity/overview/10-actions">
                <h2>인종 형평성을 위한 10가지 행동<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text">2020년 맥킨지는 인종차별 반대와 사회 정의를 위한 공개적인 약속을 발표했습니다. 아직 갈 길이 멀다는 것을 알고 있지만, 보다 정의롭고 공평한 사회를 위해
                노력하는 리더, 기업, 커뮤니티를 지원하게 되어 자랑스럽습니다.</p>
            </div>
          </div>
        </div>
        <div class="box thinking">
          <h5 class="subtitle center">관련</h5>
          <div class="grid">
            <div class="item" v-for="item in thinking">
              <el-image :src="item.img" class="img" fit="fill"></el-image>
              <div style="flex: 1;margin-top: 0.85rem;">
                <router-link class="title" :to="item.link">
                  <h4>{{ item.title }}<el-icon color="#004fff">
                      <ArrowRightBold />
                    </el-icon></h4>
                </router-link>
                <p class="text">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box thinking-2">
          <div class="grid">
            <div class="item" v-for="item in thinking2">
              <el-image :src="item.img" class="img" fit="fill"></el-image>
              <div style="flex: 1;margin-top: 0.85rem;">
                <p class="tip">{{ item.tip }}</p>
                <router-link class="title" :to="item.link">
                  <h4>{{ item.title }}<el-icon color="#004fff">
                      <ArrowRightBold />
                    </el-icon></h4>
                </router-link>
                <p class="text">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box matter">
          <h5 class="subtitle center">다양성, 형평성, 포용성의 중요성</h5>
          <div class="grid">
            <div class="item">
              <el-image :src="img11" class="img" fit="fill"></el-image>
              <div style="flex: 1;">
                <h4 class="title">맥킨지 블랙 네트워크</h4>
                <p class="text">맥킨지 블랙 네트워크는 맥킨지 앤 컴퍼니의 글로벌 친화 그룹 중 하나입니다. 이 네트워크는 모든 사람의 배경, 관점 및 역량이 존중받는 환경을 조성하고
                  유지하는 데 맥킨지가 노력하는 일부를 나타냅니다.</p>
                <router-link class="see" to="https://www.mckinsey.com/careers/meet-our-people/mckinsey-black-network">
                  <h6>회원 보기<el-icon color="#004fff">
                      <Right />
                    </el-icon></h6>
                </router-link>
              </div>
            </div>
            <div class="item">
              <el-image :src="img12" class="img" fit="fill"></el-image>
              <div style="flex: 1;">
                <h4 class="title">LGBTQ+ Equality LGBTQ+ 평등</h4>
                <p class="text">Equal at McKinsey 는 전 세계 LGBTQ+ 동료들의 활기차고 연결된 네트워크입니다. 이들은 서로의 성공을 위해 헌신하며, 뛰어난 LGBTQ+
                  인재들을 맥킨지 커뮤니티로 유치하는 데 앞장서고 있습니다.</p>
                <router-link class="see"
                  to="https://www.mckinsey.com/careers/meet-our-people/equal-colleagues-at-mckinsey">
                  <h6>회원 보기<el-icon color="#004fff">
                      <Right />
                    </el-icon></h6>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="partnerships box">
          <el-divider></el-divider>
          <h5 class="subtitle">파트너십</h5>
          <div class="grid grid-3" style="margin-top: 1rem;">
            <el-image class="img" :src="logo1"></el-image>
            <el-image class="img" :src="logo2"></el-image>
            <el-image class="img" :src="logo3"></el-image>
          </div>
        </div>
        <div class="box box-full clients">
          <h5 class="subtitle center">다양성이 고객에게 어떻게 도움이 되는가</h5>
          <div class="over">
            <div class="info">
              <h1>우리는 기업들이 경쟁 우위를 확보할 수 있도록 더 나은 다양성 및 포용성 전략을 수립하도록 도와드립니다.</h1>
              <p class="text" style="margin-top: 1rem">우리는 다양성과 포용성에 대한 약속의 일환으로 모든 산업의 고객에게 조언과 지원을 제공하여 다양성을 개선하고 포용적인
                문화를 조성함으로써 가치를 창출할 수 있도록
                자체적인 방법을 맞춤 설정하도록 돕습니다.
                저희는 광범위한 연구와 전문 지식을 바탕으로 행동 심리학 기반의 혁신적인 역량과 전문 기술 기반 솔루션을 활용하여 고객이 다양성의 이점을 얻도록 도와드립니다.</p>
            </div>
            <el-image :src="img13" class="img"></el-image>
          </div>
        </div>
        <div class="box people">
          <h5 class="subtitle center">우리 직원에 대한 이야기</h5>
          <div class="grid">
            <div class="item" v-for="item in people">
              <el-image :src="item.img" class="img" fit="fill"></el-image>
              <div style="flex: 1;margin-top: 0.85rem;">
                <router-link class="title" :to="item.link">
                  <h4>{{ item.title }}<el-icon color="#004fff">
                      <ArrowRightBold />
                    </el-icon></h4>
                </router-link>
                <p class="text">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box people-2">
          <div class="grid">
            <div class="item" v-for="item in people2">
              <el-image :src="item.img" class="img" fit="fill"></el-image>
              <div style="flex: 1;margin-top: 0.85rem;">
                <router-link class="title" :to="item.link">
                  <h4>{{ item.title }}<el-icon color="#004fff">
                      <ArrowRightBold />
                    </el-icon></h4>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer2Component />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted } from 'vue'
import useUserStore from '@/stores/user.js'
import Footer2Component from '@/components/Footer2Component.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
// import router from '@/router/index.js'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
// ------------------------------ //
// 方法
// ------------------------------ //

// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '2-3'
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //


import logo1 from '@/assets/imgs/overview/overview-logo-1.webp'
import logo2 from '@/assets/imgs/overview/overview-logo-2.webp'
import logo3 from '@/assets/imgs/overview/overview-logo-3.webp'
import img1 from '@/assets/imgs/overview/overview-1.jpeg'
import img2 from '@/assets/imgs/overview/overview-2.jpeg'
import img3 from '@/assets/imgs/overview/overview-3.jpeg'
import img4 from '@/assets/imgs/overview/overview-4.jpeg'
import img5 from '@/assets/imgs/overview/overview-5.jpeg'
import img6 from '@/assets/imgs/overview/overview-6.jpeg'
import img7 from '@/assets/imgs/overview/overview-7.jpeg'
import img8 from '@/assets/imgs/overview/overview-8.jpeg'
import img9 from '@/assets/imgs/overview/overview-9.jpeg'
import img10 from '@/assets/imgs/overview/overview-10.jpeg'
import img11 from '@/assets/imgs/overview/overview-11.jpeg'
import img12 from '@/assets/imgs/overview/overview-12.jpeg'
import img13 from '@/assets/imgs/overview/overview-13.jpeg'
import img14 from '@/assets/imgs/overview/overview-14.jpeg'
import img15 from '@/assets/imgs/overview/overview-15.jpeg'
import img16 from '@/assets/imgs/overview/overview-16.jpeg'
import img17 from '@/assets/imgs/overview/overview-17.jpeg'
import img18 from '@/assets/imgs/overview/overview-18.jpeg'
import img19 from '@/assets/imgs/overview/overview-19.jpeg'
import img20 from '@/assets/imgs/overview/overview-20.jpeg'
import { ArrowLeft, ArrowRightBold, Right } from '@element-plus/icons-vue'
import { reactive } from 'vue';

const carousel = reactive([
  {
    title: '선구적인 글로벌 연구 및 사례',
    desc: '다양성 및 포용성',
    img: img1
  },
  {
    title: '탁월한 소속감과 존중의 문화 조성',
    desc: '다양성 및 포용성',
    img: img2
  },
  {
    title: '다양성을 통한 전달',
    desc: '다양성 및 포용성',
    img: img3
  }
])

const thinking = reactive([
  { link: 'https://www.mckinsey.com/bem/our-insights/race-in-the-uk-workplace-the-intersectional-experience', img: img5, title: '영국 직장에서의 인종: 교차적 경험', text: '2023년 6월 28일 - 의미있는 진전에도 불구하고, 직장에서의 공평성을 실현하는 것은 여전히 어려운 과제임이 드러났습니다. 흑인 여성, 방글라데시 및 파키스탄 여성과 같은 집단의 문제를 해결하는 것은 훨씬 더 큰 영향을 미칠 수 있습니다.' },
  { link: 'https://www.mckinsey.com/esg-report', img: img6, title: '2022ESG Report 2022年 ESG 보고', text: '우리의 연간 보고서는 통찰력, 고객 서비스 및 회사 자체에서 이룬 중대한 진전을 요약하며, 이는 전 세계적으로 지속 가능하고 포용적인 성장을 이루었습니다.' },
])

const thinking2 = reactive([
  { link: 'https://www.mckinsey.com/industries/public-sector/our-insights/building-supportive-ecosystems-for-black-owned-us-businesses', img: img7, tip: '맥킨지 흑인 경제 이동성 연구소', title: '미국 흑인 기업을 위한 지원 생태계 구축', text: '2020년 10월 29일 - 올바른 비즈니스 생태계는 흑인 소유 기업이 사업 구축 과정에서 직면하는 구조적 장애물의 영향을 완화하거나 없앨 수 있습니다.' },
  { link: 'https://www.mckinsey.com/featured-insights/future-of-work/covid-19-and-gender-equality-countering-the-regressive-effects', img: img8, tip: 'COVID-19과 남여 평등: 퇴보적 영향 대응', title: '2020년 7월 15일 - 남여 평등은 경제와 사회에도 좋습니다. COVID-19 팬데믹은 그러한 사실을 명백하게 드러냅니다.', text: '이 연례 보고서에는 전 세계의 지속 가능하고 포용적인 성장을 촉진하는 데 있어 인사이트, 고객 서비스, 회사 전반에 걸쳐 이룬 중요한 진전이 요약되어 있습니다.' },
  { link: 'https://www.mckinsey.com/featured-insights/diversity-and-inclusion/diversity-wins-interactive', img: img9, tip: '대화 형', title: '영국 직장에서의 인종: 교차적 경험', text: '2020년 5월 20일-포용과 다양성을 위한 비즈니스 사례가 그 어느 때보다 강력해지고 있습니다.' },
  { link: 'https://www.mckinsey.com/featured-insights/diversity-and-inclusion/diversity-still-matters', img: img10, tip: '맥킨지 분기 별', title: '다양성은 여전히 중요하다', text: '2020년 5월 19일-포용과 다양성은 위기 속에서 위험에 처해 있지만 비즈니스 회복, 탄력성 및 재상상에 매우 중요합니다.' },
])

const people = reactive([
  { link: 'https://www.mckinsey.com/about-us/diversity/diversity-at-work/diversity-at-work-conversations-on-identity', img: img14, title: '직장에서의 다양성: 정체성에 대한 대화', text: '2021년 6월 10일직장에서의 다양성은 어떤 모습일까요? 다양성의 일부로서 살아가는 것은 어떤 경험일까요? 이번 대화에서는 동료들과 함께 정체성 동질성의 복잡성에 대해 논의하고, 전 세계의 동료들이 어떤 지원을 제공하는지 알아보겠습니다.' },
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/making-it-work-a-colleague-on-giving-birth-during-the-pandemic-and-returning-to-work', img: img15, title: '2022 ESG 보고서', text: '이 연례 보고서에는 전 세계의 지속 가능하고 포용적인 성장을 촉진하는 데 있어 인사이트, 고객 서비스, 회사 전반에 걸쳐 이룬 중요한 진전이 요약되어 있습니다.' },
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/inside-hollywood-the-black-experience-in-film-and-tv', img: img16, title: '영국 직장에서의 인종: 교차적 경험', text: '2023년 6월 28일 - 의미 있는 진전에도 불구하고 직장 내 형평성을 달성하는 것은 여전히 어려운 과제입니다. 흑인, 방글라데시, 파키스탄 여성과 같은 다양한 커뮤니티의 문제를 해결하면 광범위한 혜택을 얻을 수 있습니다.' },
])

const people2 = reactive([
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/in-abu-dhabi-empowering-the-next-generation-of-leaders', img: img17, title: '아부다비에서 다음 세대는 뛰어난 리더십이 머리와 마음의 완벽한 조화라는 것이라 배우고 있습니다.', text: '2023년 6월 28일 - 의미 있는 진전에도 불구하고 직장 내 형평성을 달성하는 것은 여전히 어려운 과제입니다. 흑인, 방글라데시, 파키스탄 여성과 같은 다양한 커뮤니티의 문제를 해결하면 광범위한 혜택을 얻을 수 있습니다.' },
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/our-black-voices', img: img18, title: '우리의 흑인들의 목소리:맥킨지의 흑인 동료들의 시각을 포착하기 위한 여정', text: '이 연례 보고서에는 전 세계의 지속 가능하고 포용적인 성장을 촉진하는 데 있어 인사이트, 고객 서비스, 회사 전반에 걸쳐 이룬 중요한 진전이 요약되어 있습니다.' },
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/where-are-we-on-gender-equality', img: img19, title: '양성평등에 대해 우리는 어디에 있는가?"2 보 전진, 1 보 후퇴."', text: '2023년 6월 28일 - 의미 있는 진전에도 불구하고 직장 내 형평성을 달성하는 것은 여전히 어려운 과제입니다. 흑인, 방글라데시, 파키스탄 여성과 같은 다양한 커뮤니티의 문제를 해결하면 광범위한 혜택을 얻을 수 있습니다.' },
  { link: 'https://www.mckinsey.com/about-us/new-at-mckinsey-blog/what-does-being-lgbtq-in-the-workplace-look-like-today', img: img20, title: '오늘날 직장에서의 LGBTQ+는 어떤 모습일까요?', text: '이 연례 보고서에는 전 세계의 지속 가능하고 포용적인 성장을 촉진하는 데 있어 인사이트, 고객 서비스, 회사 전반에 걸쳐 이룬 중요한 진전이 요약되어 있습니다.' },
])
</script>

<style lang="scss" scoped>
@import url("@/assets/scss/common.scss");

.item {
  position: relative;

  &:after {
    width: 100%;
    height: 100%;
    padding: 1rem;
    content: '';
    position: absolute;
    opacity: 0;
    top: -1rem;
    left: -1rem;
    box-shadow: 0px 16px 32px -1px rgba(5, 28, 44, .2), 0px 0px 1px 0px rgba(5, 28, 44, .15);
    transition: 0.15s ease-in-out;
    pointer-events: none;
    box-sizing: content-box;
  }

  .title {
    width: fit-content;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: bold;
    cursor: pointer;

    &:hover {

      h5,
      h2,
      h4 {
        color: #2251ff;
        text-decoration-line: underline;
        text-decoration-thickness: .1em;
        text-underline-offset: 0.25rem;
      }
    }
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    color: #666;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.equity {
  margin-top: 4rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .img {
      flex: 1;
    }

    .text {
      margin-top: .8rem;
      font-size: 18px;
      line-height: 2;
    }
  }
}

.thinking {
  margin-top: 4rem;

  .grid {
    margin-top: 2rem;
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .title {
      text-align: start;
    }

    .text {
      margin-top: 0.8rem;
      font-size: 16px;
      line-height: 2;
    }
  }
}

.people {
  margin-top: 4rem;

  .grid {
    margin-top: 2rem;
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .title {
      text-align: start;
    }

    .text {
      margin-top: 0.8rem;
      font-size: 16px;
      line-height: 2;
    }
  }
}

.thinking-2,
.people-2 {
  margin-top: 2rem;

  .grid {
    margin-top: 2rem;
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .title {
      text-align: start;
    }

    .text {
      margin-top: 0.8rem;
      font-size: 16px;
      line-height: 2;
    }
  }
}

.matter {
  margin-top: 4rem;

  .grid {
    margin-top: 2rem;
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    display: flex;
    gap: 1rem;

    .img {
      flex: 1
    }

    .title {
      align-items: start;
      text-align: start;
    }

    .text {
      margin: 1rem 0;
    }

    .see {
      display: flex;
      align-items: center;
      gap: 4px;

      &:hover {
        h6 {
          color: #2251ff;
        }

        .el-icon {
          transition: 0.5s ease-in-out;
          transform: translateX(0.7rem);
        }
      }
    }
  }
}

.introduce {
  .grid-3 {
    margin-top: 4rem;

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4rem 0;
      position: relative;

      h1 {
        color: #2251ff;
        margin-bottom: 0.8rem;
      }

      &::after {
        position: absolute;
        height: 100%;
        width: 1px;
        background: #333;
        content: '';
        top: 0;
        right: -1rem;
      }

      &:last-child::after {
        display: none;
      }
    }
  }
}

.clients {
  max-width: 100vw;

  .over {
    margin-top: 1rem;
    display: flex;
    background: #eee;
    gap: 2rem;
    padding: 0 0 0 calc((100vw - 1280px) / 2);
  }

  .img,
  .info {
    flex: 1;
  }

  .info {
    padding: 2rem 0;

    h2 {
      margin-bottom: 1rem;
    }
  }
}

.carousel-item {
  .info {
    position: absolute;
    bottom: 12%;
    left: 0;
    right: 0;
    opacity: 1;
    color: #fefefe;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: #fefefe;
      margin: 0.5rem 0 1rem 0;
    }
  }
}

:deep(.el-carousel__container) {
  height: 80vh;
}

.mck {
  background-image: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 100%) 100%, rgb(0 0 0 / 100%) 100%);
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}

@media (max-width: 480px) {
  :deep(.el-carousel__container) {
    height: 12rem;
  }

  .carousel-item {
    bottom: 12%;

    h1 {
      font-size: 2rem;
    }
  }

  .clients {
    padding: 0;

    .over {
      flex-direction: column;
      padding: 0;

      .info {
        padding: 2rem;
        ;
      }
    }
  }

  .introduce {
    .grid-3 {
      .info::after {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
      }

      .info:last-child::after {
        display: inline-block;
      }

      .info:first-child::after {
        display: none;
      }
    }
  }
}

// PC
@media screen and (min-width: 1024px) {
  #p23 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/banner-image-image.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/shutterstock_group-of-people-blue-background-300x166-1.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a3 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/rendered-3-3-17.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      padding: 30px 200px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;

      p {
        line-height: 30px;
      }
    }

    .a1x {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .b1 {
        width: 49.5%;
        height: 220px;
        padding: 30px 100px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    .a2 {
      width: 100%;
      height: 400px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 250px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #p23 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 100%;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/banner-image-image.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/shutterstock_group-of-people-blue-background-300x166-1.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 24px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a3 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/rendered-3-3-17.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      padding: 15px 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;

      p {
        line-height: 30px;
      }
    }

    .a1x {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      .b1 {
        width: 100%;
        padding: 15px 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    .a2 {
      width: 100%;
      height: 400px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 280px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}
</style>
