<template>
  <div id="p42sp11">
    <HeaderComponent />
    <div class="content">
      <div class="a1">
        <div class="chart-wrapper">
          <h2 style="text-align: center">코스피</h2>
          <hr style="width: 100%; color: #000" />
          <div class="tableS">
            <canvas ref="chart"></canvas>
          </div>
        </div>
      </div>
      <div class="a1">
        <div class="chart-wrapper">
          <h2 style="text-align: center">코스닥</h2>
          <hr style="width: 100%; color: #000" />
          <div class="tableS">
            <canvas ref="chart2"></canvas>
          </div>
        </div>
      </div>
      <div class="a1">
        <div class="chart-wrapper">
          <h2 style="text-align: center">코스피200</h2>
          <hr style="width: 100%; color: #000" />
          <div class="tableS">
            <canvas ref="chart3"></canvas>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { getCurrentInstance, onMounted, onUnmounted, ref } from 'vue'
import useUserStore from '@/stores/user.js'
import FooterComponent from '@/components/FooterComponent.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import { ElMessage } from 'element-plus'
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
// ------------------------------ //
// 数据
// ------------------------------ //
Chart.register(...registerables, ChartDataLabels)
const { proxy } = getCurrentInstance()
const userStore = useUserStore()
const chart = ref(null)
const chart2 = ref(null)
const chart3 = ref(null)
// ------------------------------ //
// 方法
// ------------------------------ //
function loadData() {
  let loading = proxy.$loading({
    lock: true,
    text: 'Loading ...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  proxy.$http
    .get('https://sj-investing.com/ideal/api/investors')
    .then((res) => {
      loading.close()
      switch (res.data.code) {
        case 200:
          {
            console.log(res)
            const filteredData = res.data.data.filter((item) => item.type === 0)
            const parsedData = filteredData.map((item) => JSON.parse(item.jsonData))
            const chartData = parsedData.flatMap((item) => item)
            const ctx = chart.value.getContext('2d')
            new Chart(ctx, {
              type: 'bar',
              data: {
                labels: chartData.map((d) => d.date),
                datasets: [
                  {
                    label: '개인',
                    data: chartData.map((d) => d.data1),
                    backgroundColor: 'rgb(255, 115, 0)'
                  },
                  {
                    label: '기관',
                    data: chartData.map((d) => d.data2),
                    backgroundColor: 'rgb(8, 199, 167)'
                  },
                  {
                    label: '외자',
                    data: chartData.map((d) => d.data3),
                    backgroundColor: 'rgb(112, 3, 255)'
                  }
                ]
              },
              options: {
                plugins: {
                  datalabels: {
                    color: '#444',
                    anchor: 'end',
                    align: 'top',
                    formatter: (value) => {
                      return value
                    }
                  }
                },
                scales: {
                  y: {
                    beginAtZero: true
                  }
                },
                responsive: true,
                maintainAspectRatio: false
              }
            })
          }
          break
        default:
          console.log(res)
          ElMessage.warning(res.data)
      }
    })
    .catch((error) => {
      loading.close()
      ElMessage.warning(error.toString())
    })
}
function loadData2() {
  let loading = proxy.$loading({
    lock: true,
    text: 'Loading ...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  proxy.$http
    .get('https://sj-investing.com/ideal/api/investors')
    .then((res) => {
      loading.close()
      switch (res.data.code) {
        case 200:
          {
            console.log(res)
            const filteredData = res.data.data.filter((item) => item.type === 1)
            const parsedData = filteredData.map((item) => JSON.parse(item.jsonData))
            const chartData = parsedData.flatMap((item) => item)
            const ctx = chart2.value.getContext('2d')
            new Chart(ctx, {
              type: 'bar',
              data: {
                labels: chartData.map((d) => d.date),
                datasets: [
                  {
                    label: '개인',
                    data: chartData.map((d) => d.data1),
                    backgroundColor: 'rgb(255, 115, 0)'
                  },
                  {
                    label: '기관',
                    data: chartData.map((d) => d.data2),
                    backgroundColor: 'rgb(8, 199, 167)'
                  },
                  {
                    label: '외자',
                    data: chartData.map((d) => d.data3),
                    backgroundColor: 'rgb(112, 3, 255)'
                  }
                ]
              },
              options: {
                plugins: {
                  datalabels: {
                    color: '#444',
                    anchor: 'end',
                    align: 'top',
                    formatter: (value) => {
                      return value
                    }
                  }
                },
                scales: {
                  y: {
                    beginAtZero: true
                  }
                },
                responsive: true,
                maintainAspectRatio: false
              }
            })
          }
          break
        default:
          console.log(res)
          ElMessage.warning(res.data)
      }
    })
    .catch((error) => {
      loading.close()
      ElMessage.warning(error.toString())
    })
}
function loadData3() {
  let loading = proxy.$loading({
    lock: true,
    text: 'Loading ...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  proxy.$http
    .get('https://sj-investing.com/ideal/api/investors')
    .then((res) => {
      loading.close()
      switch (res.data.code) {
        case 200:
          {
            console.log(res)
            const filteredData = res.data.data.filter((item) => item.type === 2)
            const parsedData = filteredData.map((item) => JSON.parse(item.jsonData))
            const chartData = parsedData.flatMap((item) => item)
            const ctx = chart3.value.getContext('2d')
            new Chart(ctx, {
              type: 'bar',
              data: {
                labels: chartData.map((d) => d.date),
                datasets: [
                  {
                    label: '개인',
                    data: chartData.map((d) => d.data1),
                    backgroundColor: 'rgb(255, 115, 0)'
                  },
                  {
                    label: '기관',
                    data: chartData.map((d) => d.data2),
                    backgroundColor: 'rgb(8, 199, 167)'
                  },
                  {
                    label: '외자',
                    data: chartData.map((d) => d.data3),
                    backgroundColor: 'rgb(112, 3, 255)'
                  }
                ]
              },
              options: {
                plugins: {
                  datalabels: {
                    color: '#444',
                    anchor: 'end',
                    align: 'top',
                    formatter: (value) => {
                      return value
                    }
                  }
                },
                scales: {
                  y: {
                    beginAtZero: true
                  }
                },
                responsive: true,
                maintainAspectRatio: false
              }
            })
          }
          break
        default:
          console.log(res)
          ElMessage.warning(res.data)
      }
    })
    .catch((error) => {
      loading.close()
      ElMessage.warning(error.toString())
    })
}
// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '4-2'
  loadData()
  loadData2()
  loadData3()
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => {})
// ------------------------------ //
</script>

<style lang="scss" scoped>
#p42sp11 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .a1 {
      width: 100%;
      min-height: 500px;
      max-height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;

      .chart-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .tableS {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
