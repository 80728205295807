<template>
  <div id="p31sp5">
    <HeaderComponent />
    <div class="content">
      <div class="news-container">
        <div class="news-list">
          <div
            v-for="news in paginatedNewsData"
            :key="news.id"
            class="news-item"
            @click="goToNews(news.url)"
          >
            <img v-if="news.imgUrl" :src="news.imgUrl" alt="News Image" class="news-image" />
            <div class="news-text">
              <h3>{{ news.title }}</h3>
              <p class="description">{{ news.content }}</p>
              <div class="info">
                <span class="author">{{ news.author }}</span>
                <span class="date">{{ formatDate(news.releaseDate) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">
            <el-icon><DArrowLeft /></el-icon>
          </button>
          <button
            v-for="page in paginatedRange"
            :key="page"
            :class="{ active: page === currentPage }"
            @click="page === '...' ? null : changePage(page)"
          >
            {{ page }}
          </button>
          <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">
            <el-icon><DArrowRight /></el-icon>
          </button>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import useUserStore from '@/stores/user.js'

import axios from 'axios'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import { DArrowLeft, DArrowRight } from '@element-plus/icons-vue'

export default {
  components: { DArrowLeft, DArrowRight, FooterComponent, HeaderComponent },

  name: 'NewsData',
  data() {
    return {
      currentPage: 1,
      pageSize: 6,
      totalItems: 0,
      totalPages: 0,
      newsData: [],
      error: null,
      loading: true,
      maxVisiblePages: 5,
      paginatedRange: []
    }
  },
  computed: {
    paginatedNewsData() {
      return this.newsData
    }
  },
  mounted() {
    const userStore = useUserStore()
    userStore.activeIndex = '3-1'
    this.fetchNewsData()
    this.updatePaginationRange()
  },
  methods: {
    fetchNewsData() {
      this.loading = true
      axios
        .post(
          'https://sj-investing.com/ideal/api/newsList/list15005',
          {
            title: '',
            current: this.currentPage,
            size: this.pageSize
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          console.log('Response:', response)
          this.loading = false
          if (response.data && response.data.code === 200) {
            this.newsData = response.data.data.records
            this.totalItems = response.data.data.total
            this.totalPages = Math.ceil(this.totalItems / this.pageSize)
            //   this.newsData = [response.data.data];
            console.log('已获取 News Data:', this.newsData)
            this.updatePaginationRange()
          } else {
            this.error = 'Invalid response from the API'
          }
        })
        .catch((error) => {
          this.loading = false
          console.error('Error fetching news data:', error)
          this.error = error.message || 'Error fetching news data'
        })
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page
        this.updatePaginationRange()
        this.fetchNewsData()
      }
    },
    goToNews(url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr)
      return date.toLocaleDateString()
    },
    updatePaginationRange() {
      const totalPages = this.totalPages
      const currentPage = this.currentPage
      const maxVisiblePages = this.maxVisiblePages
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
      let endPage = startPage + maxVisiblePages - 1

      if (endPage > totalPages) {
        endPage = totalPages
        startPage = Math.max(1, endPage - maxVisiblePages + 1)
      }

      this.paginatedRange = []
      if (startPage > 1) {
        this.paginatedRange.push(1)
        if (startPage > 2) {
          this.paginatedRange.push('...')
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        this.paginatedRange.push(i)
      }
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          this.paginatedRange.push('...')
        }
        this.paginatedRange.push(totalPages)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#p31sp5 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    overflow: auto;

    .pagination button.active {
      color: orange;
    }

    .news-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      //-webkit-mask-image: linear-gradient(to right, black 20%, transparent 100%);
      mask-image: linear-gradient(to right, black 20%, transparent 100%);
    }

    .news-list {
      width: 80%;
    }

    .news-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-radius: 10px;
    }

    .news-item:hover {
      background-color: #f0f0f0;
    }

    .news-image {
      width: 100px;
      height: auto;
      margin: 15px;
      border-radius: 10px;
    }

    .news-text {
      flex-grow: 1;
      margin: 10px 10px;
    }

    .news-text h3 {
      color: #000000;
    }

    .author {
      color: #8a8484;
      font-size: 13px;
      margin-right: 20px;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .date {
      color: #8a8484;
      font-size: 12px;
    }

    .news-text p {
      color: #505050;
      font-size: 15px;
    }

    .description {
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin: 50px 50px;
    }

    .pagination button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      padding: 5px;
      margin: 0 5px;
    }

    .pagination button:hover {
      color: orange;
    }
  }
}
</style>
