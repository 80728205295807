<template>
    <div>
      <!-- 内容区域 -->
      <div class="content">
        <div class="combined-tradingview">
          <!-- AsianK1 组件 -->
          <AsianK3/>
          <!-- AsianK2 组件 -->
          <AsianK4/>
        </div>
      </div>
    </div>
  </template>
      
      <script>
     
  
      import  AsianK3 from './AsianK3.vue';
      import AsianK4 from './AsianK4.vue';
     
      export default {
        data() {
        return {
          activeTab: 'tab1',  // 默认显示第一个标签页的内容
        };
      },
        components: {
       
            AsianK3,
            AsianK4
      },
      
        
      };
      </script>
      
      <style scoped>
    
 
    @media (max-width: 800px) {
      
 
    
    
    .combined-tradingview {
      flex-direction: column !important; /* 小屏幕时改为上下排列 */
    }
    
    
    
    }
    .combined-tradingview {
    display: flex; /* 使用 Flexbox 布局 */
    flex-direction: row; /* 子元素横向排列 */
    height: 100%;
    gap: 20px;
    margin: 0 20px;
  }

  .combined-tradingview > * {
    flex: 1; /* 子组件平分空间 */
  }
    
  
    
      </style>
      
      