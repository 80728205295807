<template>
  <div id="p22">
    <HeaderComponent />
    <div class="page home">



      <div class="box box-full" style="width: 100%;margin-top: 0;height: 100vh;">
        <video class="p-video" autoplay muted loop>
          <source :src="videoData.url.ogg" type="video/ogg" />
          <source :src="videoData.url.webm" type="video/webm" />
          <source :src="videoData.url.mp4" type="video/mp4" />
        </video>

        <button onclick="window.location.href = 'https://mobile.mgitop.top';" class="btn btn-primary" style="font-size: 2em; background-color: #233c98; border-color: #233c98; color: white; position: absolute; top: 20%; left: 50%; transform: translate(-50%, -50%);">
          외자기관<br>VIP 전용
        </button>




        <!-- 描述 -->
        <div class="video-desc flex column" style="gap: 1rem;">
          <h1 class="p-title">{{ videoData.info.title }}</h1>
          <span class="p-desc">{{ videoData.info.desc }}</span>
          <router-link class="see" style="color: #fefefe" to="p44sp13">



            <h6>자세히 알아보기<el-icon color="#fefefe">
                <Right />
              </el-icon></h6>
          </router-link>
        </div>
      </div>


      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">특집 기사</h5>
        <div class="grid grid-4">
          <div class="item shadow flex column" v-for="item in articleData.latestResearch">
            <el-image :src="item.cover"></el-image>
            <h4 class="title" style="margin-top: 1rem;">{{ item.title }}</h4>
            <span class="text">{{ item.desc }}</span>
          </div>
        </div>
      </div>



      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">기능</h5>
        <div class="grid grid-4">
          <div class="item shadow flex column" v-for="item in articleData.featured">
            <el-image :src="item.cover"></el-image>
            <h4 class="title" style="margin-top: 1rem;">{{ item.title }}</h4>
            <span class="text">{{ item.desc }}</span>
          </div>
        </div>
        <router-link class="see" style="color: #333;margin-top: 2rem;" to="p31sp5">
          <h6>모든 연구<el-icon color="#004fff">
              <Right />
            </el-icon></h6>
        </router-link>
      </div>

      <div class="box" style="width: 100%;margin-top: 2rem;">
        <el-divider></el-divider>
        <h5 class="subtitle">연구 테마</h5>
        <div class="grid grid-3">
          <router-link class="link" v-for="item in researchThemes" :to="item.link">
            <h5><el-icon color="#004fff">
                <ArrowRight />
              </el-icon>{{ item.name }}</h5>
          </router-link>
        </div>
      </div>




      <div class="box" style="width: 100%;margin-top: 2rem;">
        <el-divider></el-divider>
        <h5 class="subtitle">함께하는 분들</h5>
        <div class="grid grid-3">
          <div class="flex item shadow people" v-for="item in peoples" style="gap: 1rem">
            <el-avatar class="avatar" :src="item.cover"
              style="min-width: 5rem;min-height: 5rem;aspect-ratio: 1;"></el-avatar>
            <div class="info flex column">
              <router-link class="link">
                <h3>{{ item.name }}</h3>
              </router-link>
              <p class="text" style="margin-top: 0;">{{ item.desc }}</p>
            </div>
          </div>
        </div>
        <router-link class="see" style="color: #333;margin-top: 2rem;" to="p33sp7">
          <h6>더 많은 사람들<el-icon color="#004fff">
              <Right />
            </el-icon></h6>
        </router-link>
      </div>

      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">팟캐스트</h5>
        <div class="flex">
          <div class="item flex shadow" style="gap: 1rem;">
            <el-image :src="podcast.cover" class="img flex-1" fit="fill"></el-image>
            <div style="flex: 1;" class="flex-1">
              <router-link class="title" :to="podcast.link">
                <h2>{{ podcast.title }}<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text">{{ podcast.desc }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">웹캐스트</h5>
        <div class="flex">
          <div class="item flex shadow" style="gap: 1rem;">
            <el-image :src="webcast.cover" class="img flex-1" fit="fill"></el-image>
            <div style="flex: 1;" class="flex-1">
              <router-link class="title" :to="webcast.link">
                <h2>{{ webcast.title }}<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text">{{ webcast.desc }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">맥킨지 글로벌 연구소 버츄얼 이벤트</h5>
        <p style="font-weight: 200">{{ news.desc }}</p>
        <div class="grid grid-2">
          <div class="item flex shadow column" style="gap: 0rem;" v-for="item in news.list">
            <span class="tip">{{ item.title }}</span>
            <router-link class="title" :to="item.link">
              <h3>{{ item.name }}<el-icon color="#004fff">
                  <ArrowRightBold />
                </el-icon></h3>
            </router-link>
            <p class="text">{{ item.desc }}</p>
          </div>
        </div>
        <router-link class="see" style="color: #333;margin-top: 2rem;" to="p31sp5">
          <h6>더 많은 맥킨지 글로벌 연구소 관련 뉴스 보기<el-icon color="#004fff">
              <Right />
            </el-icon></h6>
        </router-link>
      </div>

    </div>

    <Footer2Component />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted } from 'vue'
import useUserStore from '@/stores/user.js'
import Footer2Component from '@/components/Footer2Component.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
// import router from '@/router/index.js'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
// ------------------------------ //
// 方法
// ------------------------------ //

// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '1'
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //

import { reactive } from 'vue';
import { ArrowRight, Right } from '@element-plus/icons-vue'
import video from '@/assets/video/home-1.mp4'
//视频数据
const videoData = reactive({
  url: {
    mp4: video
  },
  info: {
    title: '맥킨지 글로벌 연구소',
    desc: '저희의 사명은 글로벌 기업 및 정책 정책을 이끌어가는 리더들에게 가장 중요한 경제 및 비즈니스 문제에 대한 의사 결정에 도움이 되는 사실 기반을 제공하는 것입니다.'
  }
});
//文章数据
const articleData = reactive({
  latestResearch: [
    {
      link: 'https://www.mckinsey.com/mgi/our-research/investing-in-productivity-growth',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/investing%20in%20productivity%20growth/mgi-investing-in-productivity-growth-1500373494-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '중소기업에 대한 면밀한 분석 : 생산성 향상에 대한 기회 발굴',
      desc: '2024년 5월 2일 - 중소기업들은 경제 성장과 고용에 필수적이지만 생산성 향상에 있어서 어려움을 겪고 있습니다. 더 높은 생산성 향상을 위한 여정은 모든 기업이 서로 윈윈할 수 있는 경제 구조를 만드는 데 있습니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/geopolitics-and-the-geometry-of-global-trade',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/geopolitics%20and%20the%20geometry%20of%20global%20trade/global-connection-1319660554-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '생산력 증가에 대한 투자',
      desc: '2024년 3월 27일 - 투자를 늘리고 다음 생산성 흐름을 따라잡아야 할 시점입니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/accelerating-europe-competitiveness-for-a-new-era',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/accelerating%20europe%20competitiveness%20for%20a%20new%20era/pivoting-europes-economic-model-814346208-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '지리적&정치적 요인에 의한 국가정책과 세계 무역의 기하학적 조합',
      desc: '2024년 1월 17일 - 글로벌 무역 패턴이 재구성됨에 따라 더 많은 변화가 발생할 가능성이 높아졌으며 기업들은 향후 다양한 경로의 잠재적인 장단점을 인식해야 합니다.'
    },
    {
      link: 'https://www.mckinsey.com/capabilities/sustainability/our-insights/an-affordable-reliable-competitive-path-to-net-zero',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/business%20functions/sustainability/our%20insights/an%20affordable%20reliable%20competitive%20path%20to%20net%20zero/an-affordable-reliable-competative-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '유럽의 가속화 : 새로운 시대를 위한 경쟁력',
      desc: '2024년 1월 16일 - 격동의 시대는 유럽 경제의 새로운 취약성을 부각시켰습니다. 이러한 문제를 해결하는 것은 새로운 경쟁력과 성장의 물결을 위한 촉매제가 될 수 있습니다.'
    }
  ],
  featured: [
    {
      link: 'https://www.mckinsey.com/capabilities/sustainability/our-insights/an-affordable-reliable-competitive-path-to-net-zero',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/business%20functions/sustainability/our%20insights/an%20affordable%20reliable%20competitive%20path%20to%20net%20zero/an-affordable-reliable-competative-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '경제적이고 안정적이며 경쟁력 있는 탄소 중립 달성 방안',
      desc: '2023년 11월 30일 - 성공적인 탄소 중립 전환은 단 하나의 목표가 아닌 네 가지 목표를 달성해야 합니다. 저희는 이를 올바르게 달성하는 방법에 대한 가이드를 제공합니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/asia-on-the-cusp-of-a-new-era',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/asia%20on%20the%20cusp%20of%20a%20new%20era/01-cover-asia%20on%20the%20cusp%20of%20a%20new%20era-88645904-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '아시아, 새로운 시대의 변곡점에 서다.',
      desc: '2023년 9월 22일 - 아시아는 새로운 시대가 만들어지는 용광로가 될 것이며 세계적인 도전 과제의 더욱 심각한 형태를 경험할 수도 있습니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/from-poverty-to-empowerment-raising-the-bar-for-sustainable-and-inclusive-growth',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/from%20poverty%20to%20empowerment%20raising%20the%20bar%20for%20sustainable%20and%20inclusive%20growth/mgi-from%20poverty%20to%20empowerment-00-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '빈곤에서 역량의 강화로 : 지속가능하고 퐁용적인 성장을 위한 기준 향상',
      desc: '2023년 9월 18일 - 근 10년 내로 최소 생활 수준을 높이고 탄소 중립 달성을 위한 여정에는 무엇이 필요할까요? 저희의 연구는 사람과 지구라는 두 가지 중요한 목표를 함께 달성하기 위한 방법을 탐구하는 것입니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/what-could-a-new-era-mean-for-latin-america',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/generative%20ai%20and%20the%20future%20of%20work%20in%20america/the-future-of-work-mattmurphy-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '생성형 AI와 미국 일자리의 미래',
      desc: '2023년 7월 26일 - 어떤 직업의 수요가 많을까요? 어떤 직업의 수요가 줄어들까요? 그리고 어떤 일자리를 채우기 힘들까요?'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/generative-ai-and-the-future-of-work-in-america',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/what%20could%20a%20new%20era%20mean%20for%20latin%20america/what-could-a-new-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '새로운 시대가 라틴아메리카에 의미하는 바는 무엇일까요?',
      desc: '2023년 7월 20일 - 현재 세계적인 도전들은 새로운 시대를 열 수 있습니다. 이는 라틴아메리카 경제에 어떠한 영향을 미칠까요?'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/empty-spaces-and-hybrid-places',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/empty%20spaces%20and%20hybrid%20places%20the%20pandemics%20lasting%20impact%20on%20real%20estate/aga%20and%20executive%20summary/mgi-real%20estate-cover-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '텅 빈 공간과 혼합된 장소 : 팬데믹이 부동산에 미친 지속적인 영향',
      desc: '2023년 7월 13일 - 전 세계적인 스타 도시들의 부동산은 팬데믹으로 인한 사람들의 생활 방식 변화에 적응하지 못하고 있습니다. 이는 도시 활력에 위협이 되며 도시는 새로운 상황에 적응해야할 것으로 보입니다.'
    },
    {
      link: 'https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/the-economic-potential-of-generative-ai-the-next-productivity-frontier',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20digital/our%20insights/the%20economic%20potential%20of%20generative%20ai%20the%20next%20productivity%20frontier/the-economic-potential-of-generative-ai-1324915617-thumb-1536x1536.jpg?mw=677&car=42:25',
      title: '생성형 AI의 경제적 잠재력 : 차세대 생산성의 프런티어',
      desc: '2023년 6월 14일 - 생성형 AI는 다음 단계의 생산성 향상 흐름을 촉발할 준비가 되어있습니다. 저희는 비즈니스 가치가 창출될 수 있는 분야와 노동력에 미칠 수 있는 잠재적인 영향에 대해 초보적인 탐구를 진행합니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/reimagining-economic-growth-in-africa-turning-diversity-into-opportunity',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/reimagining%20economic%20growth%20in%20africa%20turning%20diversity%20into%20opportunity/mgi-africa-growth-1412466945-standard-1536x1536.jpg?mw=677&car=42:25',
      title: '아프리카 경제 성장의 재구상 :  다양성을 기회로 전환하기',
      desc: '지난 10년간 아프리카의 경제는 침체되었지만 전체 인구의 절반은 대륙에서 가장 번역한 국가들에 거주하고 있습니다. 아프리카는 생산성 향상과 경제 성장 재구상을 위한 인적 자본 및 천연 자원을 보유하고 있으며 이는 글로벌 복지에 있어 더 중요한 요소입니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/overview/the-future-of-wealth-and-growth-hangs-in-the-balance',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/overview/the%20future%20of%20wealth%20and%20growth%20hangs%20in%20the%20balance/mgi-the%20future%20of%20wealth%20and%20growth_00_cover_109720633_standard_1536x1536.jpg?mw=677&car=42:25',
      title: '미래의 부와 성장은 균형에 달려있습니다.',
      desc: '2023년 5월 24일 - 지난 20년간 160조 달러 규모의 종이의 부*(실제 가치보다 자산 가격 상승에 따라 서류상으로만 증가된 부, 부의 허상, 가짜 호황) 가 창출되었지만, 경제 성장은 저조하고 불평등은 심화되었습니다. 앞으로는 어떻게 될까요?'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/rekindling-us-productivity-for-a-new-era',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/rekindling%20us%20productivity%20for%20a%20new%20era/rekindling-1423028926-standard-1536x1536.jpg?mw=677&car=42:25',
      title: '새로운 시대를 위한 미국 생산성의 재점화',
      desc: '2023년 2월 16일 - 역사적인 생산성과 성장률을 회복하게 되면 미국의 GDP는 10조 달러 증가로 이어질 것이며 이는 노동력 부족, 부채, 인플레이션 및 에너지 전환에 대응하는 데 필요한 추진력으로 작용될 것입니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/the-complication-of-concentration-in-global-trade',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/the%20complication%20of%20concentration%20in%20global%20trade/jpg_thumb_mgi%20concentration_1536x1536.jpg?mw=677&car=42:25',
      title: '글로벌 무역 집중화의 복잡성',
      desc: '2023년 1월 12일 - 교역 제품의 원산지 집중화가 광범위하게 이루어지면서 다각화 또는 경제적 의존도를 낮추는 분리 전략의 필요성에 대한 의문이 제기되고 있습니다.'
    },
    {
      link: 'https://www.mckinsey.com/mgi/our-research/pixels-of-progress-introduction',
      cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/our%20research/pixels%20of%20progress/mgi-pixels-landing-page-1432556452-standard-1536x1536.jpg?mw=677&car=42:25',
      title: '발전의 픽셀 : 전 세계 인류 발전에 대한 세밀한 관찰',
      desc: '2022년 12월 7일 - 우리의 세계는 크고 복잡하지만 인류의 발전은 여전히 지상의 삶과 근거리 및 일상생활과 불가분의 관계에 있습니다.'
    }
  ]
});
//搜索主题
const researchThemes = reactive([
  {
    link: 'p31sp5',
    name: '생산성 & 번영'
  },
  {
    link: 'p32sp6',
    name: '글로벌 커넥션 (또는 글로벌 연결)'
  },
  {
    link: 'p33sp7',
    name: '미래기술 & 시장 (또는 미래의 기술 및 시장)'
  },
  {
    link: 'p34sp8',
    name: '인간의 잠재력'
  },
  {
    link: 'p35sp9',
    name: '세계의 자원'
  },
  {
    link: 'p36sp10',
    name: '함께하는 분들'
  }
]);
//人
const peoples = reactive([
  {
    link: 'p41sp10',
    cover: 'https://www.mckinsey.com/~/media/mckinsey/our%20people/chris%20bradley/chris-bradley_8317_fc-mask_988x741.png?mw=250&car=1:1',
    name: '크리스 브래들리 - ',
    desc: '맥킨지 글로벌 연구소 이사 겸 수석 파트너, 시드니'
  },
  {
    link: 'p42sp11',
    cover: 'https://www.mckinsey.com/~/media/mckinsey/our%20people/kweilin%20ellingrud/kweilin_ellingrud_fc-mask_headshot_988x741.jpg?mw=250&car=1:1',
    name: '퀼린 엘린그뤼드',
    desc: '맥킨지 글로벌 연구소 이사 겸 수석 파트너, 미니애폴리스'
  },
  {
    link: 'p43sp12',
    cover: 'https://www.mckinsey.com/~/media/mckinsey/our%20people/sylvain%20johansson/sylvain-johansson_fc-mask_headshot_988x741.jpg?mw=250&car=1:1',
    name: '실베인 요한슨',
    desc: '맥킨지 글로벌 연구소 소장 겸 수석 파트너, 제네바'
  },
  {
    link: 'p44sp13',
    cover: 'https://www.mckinsey.com/~/media/mckinsey/our%20people/sven%20smit/v2/sven-smit_headshot_988x741.jpg?mw=250&car=1:1',
    name: '스벤 스미트',
    desc: '맥킨지 글로벌 연구소 회장 겸 수석 파트너, 암스테르담'
  },
  {
    link: 'p45sp14',
    cover: 'https://www.mckinsey.com/~/media/mckinsey/our%20people/olivia%20white/olivia-white_headshot_988x741.jpg?mw=250&car=1:1',
    name: '올리비아 화이트',
    desc: '맥킨지 글로벌 연구소 이사 겸 수석 파트너, 베이 지역'
  },
  {
    link: 'p46sp15',
    cover: 'https://www.mckinsey.com/~/media/mckinsey/our%20people/lola%20woetzel/lola%20woetzel_headshot_988x741.jpg?mw=250&car=1:1',
    name: '로라 워첼',
    desc: '맥킨지 글로벌 연구소 이사 겸 수석 파트너, 상하이'
  }
]);
const podcast = reactive({
  link: 'https://www.mckinsey.com/mgi/forward-thinking',
  cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/forward%20thinking/png-mgi-forward-thinking-hero-1536x1536.png?mw=768&car=16:9',
  title: '미래 지향적인 사고방식',
  desc: '맥킨지 글로벌 연구소에서는 오늘날의 사고를 이끄는 리더들과의 대화를 통해 내일의 영감을 이끌어냅니다. 공동 진행자 마이클 추이(Micheal Chui)와 재닛 부시(Janet Bush)는 혁신적인 사상가 및 업계 선구자들과 인터뷰를 진행하며 오늘날과 미래 세계 경재를 재편하는 트랜드에 대해 탐구합니다.'
});

const webcast = reactive({
  link: 'https://www.mckinsey.com/mgi/virtual-events',
  cover: 'https://www.mckinsey.com/~/media/mckinsey/mckinsey%20global%20institute/overview/mgi%20virtual%20events/hero%20image.png?mw=768&car=16:9',
  title: '맥킨지 글로벌 연구소 버츄얼 이벤트',
  desc: '맥킨지 글로벌 연구소의 최신 연구에 대한 토론회에 참여하시기 바랍니다. 본 이벤트는 프리젠테이션, 패널 토론, 맥킨지 글로벌 연구소 경영진 및 기타 전문가와의 Q&A로 구성됩니다.'
});

const news = reactive({
  desc: '맥킨지 글로벌 연구소에서 발간되는 연구 보고서는 국제 언론에서 자주 인용되며 MGI 연구원들은 주요 비즈니스 간행물을 선도하는데 기여하고 있습니다.',
  list: [
    {
      link: 'https://www.mckinsey.com/mgi/overview/in-the-news/how-countries-and-companies-can-rev-up-the-productivity-engine',
      title: '기사 - 포브스',
      name: '국가와 기업이 생산성 향상을 가속화 할 수 있는 방법',
      desc: '2024년 4월 24일 - 생산성 향상은 경제적 성공을 촉진하는 비결입니다. 이는 투입물로부터 얼마나 많은 산출물을 얻을 수 있는지를 결정하며...'
    },
    {
      link: 'https://www.mckinsey.com/mgi/overview/in-the-news/the-global-economy-is-resetting-china-is-repositioning-itself-to-export-innovative-technologies-and-its-trading-partners-are-more-diverse',
      title: '기사 - 홍콩 이코노믹 타임즈',
      name: '글로벌 경제가 재편되고 있고 중국은 혁신기술을 수출하기 위해 스스로의 입지를 재설정하고 있으며 무역 파트너는 더욱 다양해지고 있습니다.',
      desc: '2024년 4월 22일 - 세계 경제 환경과 지정학적 영향으로 인해 여러 직역의 무역관계가 재설정되고 있으며...'
    }
  ]
});
</script>

<style lang="scss" scoped>
@import url("@/assets/scss/common.scss");

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.video-desc {
  position: absolute;
  width: 80vw;
  left: 10vw;
  bottom: 3rem;
  color: #FFF;

  .p-title {
    font-size: 52px;
    line-height: 1.15em;
  }

  .p-desc {
    display: block;
    font-size: 20px;
    line-height: 1.6em;
  }

  .learn-more {
    margin-top: 16px;

    &:hover {
      .icon {
        transform: translateX(16px);
      }
    }

    .icon {
      margin-left: 20px;
      transition: all 0.4s;
    }
  }
}

@media screen and (max-width: 1440px) {
  article {
    width: 80vw;
  }

  .article-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  article {
    width: 80vw;
  }

  .article-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .research-themes-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .people-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .avatar {
    height: 6rem !important;
  }

  .people {
    flex-direction: row !important;
  }

  .video-container {
    &.p-video-container {
      height: unset;
    }

    .p-video {
      height: 814px;
    }

    .video-desc {
      width: 90%;
      left: 5%;
    }
  }

  article {
    width: 90vw;
  }

  .article-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .research-themes-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .people-list {
    grid-template-columns: repeat(1, 1fr);

    .people-item {
      .cover {
        width: 65px;
        height: 65px;
      }
    }
  }

  .article-box {
    flex-direction: column;

    .cover {
      width: 100%;
      margin-right: 0;
    }

    .info-box {
      .p-title {
        font-size: 28px;
      }
    }
  }

  .news-list {
    grid-template-columns: repeat(1, 1fr);

    .p-news-item {
      width: 100%;

      +.p-news-item {
        margin-top: 36px;
      }
    }
  }
}

// PC
@media screen and (min-width: 1024px) {
  #p22 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/shutterstock_223745608-for-website.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/future.png') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      height: 364px;
      padding: 30px 200px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;

      p {
        line-height: 30px;
      }
    }

    .a2 {
      width: 100%;
      height: 500px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 250px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #p22 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 100%;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/shutterstock_223745608-for-website.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/future.png') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 24px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      padding: 15px 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;

      p {
        line-height: 30px;
      }
    }

    .a2 {
      width: 100%;
      height: 500px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 280px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}
</style>
