<template>
  <div id="p24">
    <HeaderComponent />
    <div class="capability page">
      <div class="box-left web-top"
        style="background-image: radial-gradient(circle farthest-corner at top right, #00a9f4 14%, #2251ff 50%, #042a76 100%) !important;">
        <div class="flex">
          <div class="info flex column flex-1 absoult top-center"
            style="left: 0;margin-right: 60%;color: #fefefe;gap:2rem;">
            <h1 class="title" style="font-size: 4rem;">전략 및 기업 금융</h1>
            <span class="subtitle" style="font-size: 0.8rem"><router-link class="line"
                to="https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-people">대담한 전략을 통해 지속 가능하고
                포용적인 성장을 가속화하고자 합니다.</router-link></span>
          </div>
          <el-image class="flex-1" :src="Img1" style="pointer-events: none;"></el-image>
        </div>
      </div>

      <div class="box" style="width: 100%;margin-top: 2rem;">
        <el-divider></el-divider>
        <h5 class="subtitle">고객을 돕는 방법</h5>
        <div class="grid grid-3" style="gap: 1rem;">
          <router-link class="link" v-for="item in clients" :to="item.link">
            <h5 style="font-weight: 400;"><el-icon color="#004fff">
                <ArrowRight />
              </el-icon>{{ item.name }}</h5>
          </router-link>
        </div>
      </div>

      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">우리의 접근 방식</h5>
        <div class="grid grid-3">
          <div class="item" v-for="item in approach">
            <h3 class="title">{{ item.title }}</h3>
            <span class="text">{{ item.text }}</span>
          </div>
        </div>
      </div>

      <div class="box box-full" style="background: #051c2c;width: 100%;margin-top: 4rem;">
        <div class="flex">
          <div class="flex-1 absoult absoult-right" style="overflow: hidden;">
            <el-image :src="Img2" fit="contain" style="width: 100%;height: 100%;margin: 0 auto;"></el-image>
          </div>
          <div class="info flex column absoult-left" style="width: 50%;">
            <div style="padding: 3rem;color: #fefefe;">
              <h1 class="title" style="margin-bottom: 2rem;">마케팅 그 이상의 가치 창출
              </h1>
              <h4 class="subtitle">전략부터 규모까지 기술의 약속을 실현합니다.</h4>
              <router-link class="see" style="color: #fefefe"
                to="https://www.mckinsey.com/capabilities/mckinsey-digital/how-we-help-clients??cid=njt-ste-crk-mbm-mbm--2403-promo02-njt-bam-web">
                <h5>회원 보기<el-icon color="#fefefe">
                    <Right />
                  </el-icon></h5>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="box" style="width: 100%;">
        <el-divider></el-divider>
        <h5 class="subtitle">금융에 대한 맥킨지</h5>
        <div class="item flex shadow" style="gap: 1rem;">
          <el-image :src="Img4" class="flex-1" fit="fill"></el-image>
          <div style="flex: 1;">
            <span class="tip">이슈</span>
            <router-link class="title"
              to="https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/mckinsey-on-finance/mckinsey-on-finance-number-84">
              <h2>맥킨지금융 제 84 기<el-icon color="#004fff">
                  <ArrowRightBold />
                </el-icon></h2>
            </router-link>
            <p class="text" style="margin: 1rem 0">2023년 12월 18일 - 기업 재무 및 전략 전망
            </p>
            <router-link class="see" style="color: #333"
              to="https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/mckinsey-on-finance">
              <h5>이전 간행물 보기<el-icon color="#004fff">
                  <Right />
                </el-icon></h5>
            </router-link>
          </div>
        </div>
      </div>

      <div class="box" style="width: 100%;">
        <el-divider></el-divider>
        <h5 class="subtitle">우리의 마인드 리더쉽</h5>
        <div class="grid grid-3">
          <div class="item flex column shadow" style="gap: 1rem;height: fit-content;" v-for="item in leadership">
            <el-image :src="item.img" class="flex-1" fit="fill"></el-image>
            <div style="flex: 1;">
              <span class="tip">{{ item.tip }}</span>
              <router-link class="title" :to="item.link">
                <h2>{{ item.title }}<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text" style="margin: 1rem 0">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="box" style="width: 100%;">
        <el-divider></el-divider>
        <h5 class="subtitle">주요 기능</h5>
        <div class="grid grid-2">
          <div class="item flex column shadow" style="gap: 1rem;height: fit-content;" v-for="item in capabilities">
            <el-image :src="item.img" class="flex-1" fit="fill"></el-image>
            <div class="flex-1">
              <router-link class="title">
                <h2>{{ item.title }}<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text" style="margin: 1rem 0">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="box" style="width: 100%;">
        <el-divider></el-divider>
        <h5 class="subtitle">사고 리더십</h5>
        <div class="grid grid-3">
          <div class="item flex column shadow" style="gap: 1rem;height: fit-content;" v-for="item in insights">
            <el-image :src="item.img" class="flex-1" fit="fill"></el-image>
            <div style="flex: 1;">
              <span class="tip">{{ item.tip }}</span>
              <router-link class="title">
                <h4>{{ item.title }}<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h4>
              </router-link>
              <p class="text" style="margin: 1rem 0">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="box" style="width: 100%;margin-top: 2rem;">
        <el-divider></el-divider>
        <h5 class="subtitle">우리 사람들</h5>
        <div class="grid grid-3">
          <div class="flex item shadow people" v-for="item in peoples" style="gap: 1rem">
            <el-avatar class="avatar" :src="item.cover"
              style="min-width: 5rem;min-height: 5rem;aspect-ratio: 1;"></el-avatar>
            <div class="info flex column">
              <router-link class="link" to="https://www.mckinsey.com/our-people/ankur-agrawal">
                <h3>{{ item.name }}</h3>
              </router-link>
              <p class="text" style="margin-top: 0;">{{ item.tip }}</p>
              <p class="text" style="margin-top: 0;">{{ item.desc }}</p>
            </div>
          </div>
        </div>
        <router-link class="see" style="color: #333;margin-top: 2rem;" to="/p34sp8">
          <h6>더 많은 사람<el-icon color="#004fff">
              <Right />
            </el-icon></h6>
        </router-link>
      </div>
    </div>
    <Footer2Component />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted } from 'vue'
import useUserStore from '@/stores/user.js'
import Footer2Component from '@/components/Footer2Component.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
// import router from '@/router/index.js'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
// ------------------------------ //
// 方法
// ------------------------------ //

// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '2-5'
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //

import { ArrowRight, Right, ArrowRightBold } from '@element-plus/icons-vue'
import Img1 from '@/assets/imgs/capability/capability-1.png'
import Img2 from '@/assets/imgs/capability/capability-2.png'
import Img3 from '@/assets/imgs/capability/capability-3.jpeg'
import Img4 from '@/assets/imgs/capability/capability-4.jpeg'
import Img5 from '@/assets/imgs/capability/capability-5.jpeg'
import Img6 from '@/assets/imgs/capability/capability-6.jpeg'
import Img7 from '@/assets/imgs/capability/capability-7.jpeg'
import Img8 from '@/assets/imgs/capability/capability-8.jpeg'
import Img9 from '@/assets/imgs/capability/capability-9.jpeg'
import Img10 from '@/assets/imgs/capability/capability-10.jpeg'
import Img11 from '@/assets/imgs/capability/capability-11.jpeg'
import Avatar1 from '@/assets/imgs/capability/capability-avatar-1.jpeg'
import Avatar2 from '@/assets/imgs/capability/capability-avatar-2.jpeg'
import Avatar3 from '@/assets/imgs/capability/capability-avatar-3.png'
import Avatar4 from '@/assets/imgs/capability/capability-avatar-4.png'
import Avatar5 from '@/assets/imgs/capability/capability-avatar-5.png'
import Avatar6 from '@/assets/imgs/capability/capability-avatar-6.png'
import Avatar7 from '@/assets/imgs/capability/capability-avatar-7.jpeg'
import Avatar8 from '@/assets/imgs/capability/capability-avatar-8.jpeg'
import Avatar9 from '@/assets/imgs/capability/capability-avatar-9.jpeg'
import Avatar10 from '@/assets/imgs/capability/capability-avatar-10.jpeg'
import Avatar11 from '@/assets/imgs/capability/capability-avatar-11.jpeg'
import Avatar12 from '@/assets/imgs/capability/capability-avatar-12.jpeg'
import Avatar13 from '@/assets/imgs/capability/capability-avatar-13.jpeg'
import Avatar14 from '@/assets/imgs/capability/capability-avatar-14.jpeg'

import { reactive } from 'vue';

const clients = reactive([
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/boardservices', name: '이사회서비스' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/executive-immersions', name: '뛰어난 CEO' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/strategic-growth-and-innovation', name: '디지털 전략' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/ceo-excellence', name: '디지털 전략' },
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/how-we-help-clients', name: '경영진 몰입' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/strategy', name: '기업간 인수합병' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/corporate-finance', name: '포트폴리오 전략' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/portfolio-strategy', name: '규제전략' },
  { link: 'https://www.mckinsey.com/capabilities/sustainability/how-we-help-clients/sustainability-and-social-impact-strategies', name: '전략적 성장 및 혁신' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/digital-strategy', name: '전략' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/regulatory-strategy', name: '지속가능성과 사회적 영향 전략' },
])

const approach = reactive([
  { title: '글로벌 전략 사고의 선두주자', text: '저희 설립자들은 기업전략분야의 선구자였습니다. 그 이후로 우리는 고객들이 직면하는 변화하는 과제에 맞추어 지속적으로 발전해 왔습니다. 최첨단 전략 지식과 독특한 데이터 세트, 그리고 고객의 특정 상황에 맞춤화된 도구를 융합하여 최고의 서비스를 제공합니다.' },
  { title: '심층적인 업계 인사이트', text: '크리테오는 지리적 특성과 부문의 뉘앙스를 고려한 고객 산업에 대한 심도 있는 지식을 갖춘 업계 전문가들로 구성된 심층적인 벤치를 보유하고 있습니다.' },
  { title: '고객 영향에 대한 집착', text: '우리는 고객의 상황에 맞게 맞춤 전략 컨설팅을 제공합니다. 저희는 전문성과 조직의 변화 저항을 극복하는 능력을 겸비하는데 자부심을 갖고 있으며, 고객님의 중요한 변화 계획을 실현하도록 지원합니다.' }
])

const leadership = reactive([
  { link: 'https://www.mckinsey.com/featured-insights/mckinsey-on-books/ceo-excellence', img: Img4, title: '뛰어난 CEO', text: '세계적인 경영 컨설팅 기업 맥킨지 앤 컴퍼니에서 뛰어난 CEO들이 어떻게 일을 하는지 광범위한 인터뷰를 바탕으로 깊이 있는 통찰력과 혁신적인 시각을 제공합니다.', tip: '도서' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/strategy-beyond-the-hockey-stick', img: Img5, title: '하키 스틱 너머의 전략', text: '대담한 전략으로 확률을 이기는 방법에 대한 새로운 책가치평가 7판', tip: '도서' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/valuation-measuring-and-managing-the-value-of-companies', img: Img6, title: '밸류에이션: 기업의 가치 측정 및 관리, 7판', text: '기업 전략과 재무의 교차점에는 가치 평가가 있습니다. 이 책은 신진 전문가부터 모든 사람이 ...', tip: '도서 - 실무 역량' }
])

const capabilities = reactive([
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/how-we-help-clients/stac', img: Img7, title: '전략분석센터', text: '우리는 기업 실적, 가치 창출, 거시경제적 요인, 그리고 글로벌 및 지역 트렌드에 대한 심층적인 이해를 통해 고객이 더 나은 전략적 의사 결정을 내릴 수 있도록 지원합니다..' },
  { link: 'https://finance.mckinsey.digital/', img: Img8, title: '가치있는 인텔리전스 플랫폼', text: '기업 성과에 대한 종합적인 통찰력을 제공하는 당사의 플랫폼입니다.' },
])

const insights = reactive([
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/seeing-around-corners-how-to-excel-as-a-chief-of-staff', img: Img9, title: '뛰어난 보좌관으로서 성공하는 방법', text: '2023년 10월 31일 - 보좌관은 CEO의 성공에 중요한 역할을 할 수 있을 뿐만 아니라 자신의 경력도 발전시킬 수 있습니다. 여기에 여덟 가지의 요소가 있습니다...', tip: '기사' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/innovative-growers-a-view-from-the-top', img: Img10, title: '혁신적으로 성장하는 기업: 위에서 내려다본 전경', text: '2023년 11월 1일 - 맥킨지 연구에 따르면 열망, 활성화, 실행에 초점을 맞추면 기업이 동종업계보다 혁신하고 성장하는 데 도움이 될 수 있습니다....', tip: '기사 - 맥킨지 분기별' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/building-a-world-class-digital-finance-function', img: Img11, title: '세계적 수준의 디지털 재무 기능 구축', text: '2023년 10월 20일 - CFO가 디지털 트랜스포메이션을 통해 조직의 미래 지향성과 복원력을 강화하는 방법', tip: '팟캐스트' },
])

const peoples = reactive([
  {
    cover: Avatar1,
    name: '안쿠르 아그라왈',
    tip: '파트너, 뉴욕',
    desc: '의료 시스템, 제약 및 의료 장비 회사들을 위한 전략 및 기업 재정 문제에 초점을 맞추고 있습니다...'
  },
  {
    cover: Avatar2,
    name: '매트 밴홀저',
    tip: '파트너, 시카고',
    desc: '혁신적인 혁신을 추구하여 기업의 전략적 성장과 자원 배분 최적화 지원'
  },
  {
    cover: Avatar3,
    name: '마이클 버샨',
    tip: '수석 파트너, 런던',
    desc: '전략 및 기업 재무 실무의 글로벌 리더로, 대담하고 결단력 있는 리더들과 협력하여 전략을 수립하고 혁신을 이루도록 지원합니다.'
  },
  {
    cover: Avatar4,
    name: '최원식',
    tip: '시니어 파트너, 서울',
    desc: '맥킨지 전략 및 회사 금융 실무의 리더이며 맥킨시 한국 지사의 시니어 파트너이기도 합니다.'
  },
  {
    cover: Avatar5,
    name: '알렉스 다미코',
    tip: '스탬포드 시니어 파트너',
    desc: '고객들이 기업 전략을 수립하고 신속한 성과 향상을 추구하며 강화함으로써 장기적인 가치를 창출할 수 있도록 안내합니다...'
  },
  {
    cover: Avatar6,
    name: '캐롤린 듀어',
    tip: '수석 파트너, 베이 지역',
    desc: '대규모 성과 향상 프로그램을 제공하여 문화 변화를 촉진하고, 수석 경영진들에게 리더십 결정을 지원합니다.'
  },
  {
    cover: Avatar7,
    name: '레베카 도허티',
    tip: '파트너, 베이 지역',
    desc: '고위 경영진에게 성장 전략을 컨설팅해주고 기업들과 함께 성장변화 및 M&A실행을 지원합니다.'
  },
  {
    cover: Avatar8,
    name: '로라 퍼스텐탈 박사',
    tip: '수석 파트너, 베이 지역',
    desc: '전 세계 의료 기관, 비영리 단체, 정부 기관 그리고 노벨 수상자를 포함한 다양한 고객들을 대상으로 혁신을 지원합니다...'
  },
  {
    cover: Avatar9,
    name: '제이크 헨리',
    tip: '선임 파트너, 시카고',
    desc: '합병, 인수, 분리, 매각 및 JV/제휴에 관한 McKinsey의 글로벌 업무를 이끌고 있습니다. 선도적 인 의료 서비스 제공 ...'
  },
  {
    cover: Avatar10,
    name: '셀리아 후버',
    tip: '베이 지역 수석 파트너',
    desc: '북미 이사회 서비스 부문을 총괄하며, 기업,조직들이 글로벌 시장의 변화에 적응하여 성공할 수 있도록 전략을 제공합니다.'
  },
  {
    cover: Avatar11,
    name: '존 켈러허',
    tip: 'RTS 수석 파트너, 토론토',
    desc: '북미 지역의 이사회 서비스 부문을 이끌며, 기관들에게 글로벌 맥락에서 번영하기 위한 전략에 대한 조언을 제공합니다.'
  },
  {
    cover: Avatar12,
    name: '알레한드로 크렐',
    tip: '수석 파트너, 산티아고',
    desc: '칠레를 중심으로 한 대기업들이 전략을 구축하고 운영의 우수성을 구축하며 강화하는 데 지원합니다.'
  },
  {
    cover: Avatar13,
    name: '미에케 반 오스텐데',
    tip: '수석 파트너, 브뤼셀',
    desc: '금융 서비스 업종의 고객을 대상으로, 특히 전략, 조직, 리스크 관리 분야에서 지원하며, 글로벌 평판을 가지고 있습니다...'
  },
  {
    cover: Avatar14,
    name: '앤디 웨스트',
    tip: '수석 파트너, 보스턴',
    desc: '우리 전략 & 기업금융 부문의 글로벌 공동 리더이며, 기업들이 지속가능하고 포용적인 성장을 가속화하도록 돕습니다...'
  },
])
</script>

<style lang="scss" scoped>
@import url("@/assets/scss/common.scss");
@media screen and (max-width: 768px) {
  .people {
    flex-direction: row !important;
  }
}

// PC
@media screen and (min-width: 1024px) {
  #p24 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/rendered-NT1-Copy-updated-2-15-17.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/pic012524.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a3 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/web-pic.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      padding: 30px 200px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;

      p {
        line-height: 30px;
      }
    }

    .a1x {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .b1 {
        width: 33%;
        height: 220px;
        padding: 15px 30px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    .a2 {
      width: 100%;
      height: 400px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 280px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #p24 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 100%;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/rendered-NT1-Copy-updated-2-15-17.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/pic012524.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 24px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a3 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/web-pic.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      padding: 15px 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;

      p {
        line-height: 30px;
      }
    }

    .a1x {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .b1 {
        width: 100%;
        padding: 15px 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    .a2 {
      width: 100%;
      height: 400px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 280px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}
</style>
