<template>
  <div id="p25">
    <HeaderComponent />
    <div class="capability page">
      <div class="box-left web-top"
        style="background-image: radial-gradient(circle farthest-corner at top right, #00a9f4 14%, #2251ff 50%, #042a76 100%) !important;">
        <div class="flex">
          <div class="info flex column flex-1 absoult top-center" style="left: 0;margin-right: 60%;color: #fefefe;">
            <h1 class="title" style="font-size: 4rem;">M&A</h1>
            <span class="subtitle" style="font-size: 0.8rem;margin-top: 2rem;"><router-link class="line"
                to="https://www.mckinsey.com/about-us/overview/sustainable-and-inclusive-growth">
              </router-link>우리는 원활하고 가치를 창출하는 거래
              방법에 대해 알고 있습니다.</span>
          </div>
          <el-image class="flex-1" :src="Img1" style="pointer-events: none;"></el-image>
        </div>
      </div>

      <div class="box" style="margin-top: 2rem;">
        <div class="grid grid-2">
          <div class="text">성공적이고 완벽하게 실행되며 거래만큼 가치 창출 기회를 제공하는 케이스는 거의 존재하지 않습니다. 우리는 클라이언트의 M&A 성공을 극대화하기 위해 파트너십을
            체결합니다.</div>
          <div class="text">우리는 거래 및 통합 전문성, 심층적인 산업 지식, 약 100여년에 걸쳐 축적된 글로벌 네트워크, 그리고 M&A 프로그램을 장기적으로 강화하기 위한 제도 및 임원
            M&A 역량 구축에 중점을 두고 클라이언트에게 타의 추종을 불허하는 서비스를 제공합니다.</div>
        </div>
      </div>

      <div class="box" style="width: 100%;margin-top: 2rem;">
        <el-divider></el-divider>
        <h5 class="subtitle">우리가 클라이언트를 서포트하는 방법</h5>
        <div class="grid grid-3" style="gap: 1rem;">
          <router-link class="link" v-for="item in clients" :to="item.link">
            <h5 style="font-weight: 400;"><el-icon color="#004fff">
                <ArrowRight />
              </el-icon>{{ item.name }}</h5>
          </router-link>
        </div>
      </div>

      <div class="box" style="width: 100%;margin-top: 2rem;">
        <h5 class="subtitle center">우리의 방법</h5>
        <div class="flex column">
          <el-image :src="Img2" fit="cover"></el-image>
          <div class="text" style="font-size: 0.8rem;margin-top: 2rem;">
            <p class="text">{{ approach.title }}</p>
            <ul>
              <li style="margin: 1rem 0 1rem 3rem;" v-for="item in approach.list">
                <p><b>{{ item.b }}</b>{{ item.text }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="box">
        <h5 class="subtitle center">우리의 자산</h5>
        <p class="text center">임원 학습을 가속화하기 위한 다양한 M&A 자산을 제공합니다:</p>
        <div class="grid grid-3">
          <div class="item" v-for="item in capabilites">
            <h3 class="title">{{ item.title }}</h3>
            <span class="text">{{ item.text }}</span>
          </div>
        </div>
      </div>

      <div class="box box-full" style="background: #eee;width: 100%;padding: 3rem ;">
        <h5 class="subtitle center">우리 사람들</h5>
        <div class="grid grid-3" style="gap: 0.2rem;">
          <div class="item flex column" style="background: #fff;padding: 3rem;align-items: center;"
            v-for="item in people">
            <el-avatar :src="item.cover" class="avatar" style="width: 10rem;height: 10rem;margin-bottom: 2rem;" />
            <div class="info flex column" style="align-items: center;">
              <h2 class="title">{{ item.name }}</h2>
              <h5 class="subtitle" style="margin-bottom: 0;">{{ item.tip }}</h5>
              <el-divider style="margin: 1rem 0"></el-divider>
              <span class="text center">{{ item.desc }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">주요 인사이트</h5>
        <div class="grid">
          <div class="item shadow flex" style="gap: 1rem">
            <el-image :src="Img3" class="flex-1" fit="fill"></el-image>
            <div class="flex-1">
              <span class="tip">기사</span>
              <router-link class="title"
                to="https://www.mckinsey.com/capabilities/m-and-a/our-insights/the-flip-side-of-large-m-and-a-deals">
                <h2>대형 M&A 거래의 이면<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text">2022년 3월 25일 - 연구에 따르면 대형 M&A 거래를 추구하는 회사들이 업계 평균 수준보다 더 나은 성과를 낼 가능성이 50대 50이라는 결과가
                나왔습니다. 이러한 가능성을 높이기 위해 기업의 임원들이 취할 수 있는 네 가지 조치는 다음과 같습니다.</p>
            </div>
          </div>
        </div>
        <div class="grid grid-4" style="margin-top: 3rem">
          <div class="item flex column shadow" style="gap: 1rem;" v-for="item in insights">
            <div style="height: fit-content;">
              <el-image :src="item.img" class="flex-1" fit="fill"></el-image>
              <div style="flex: 1;">
                <span class="tip">{{ item.tip }}</span>
                <router-link class="title">
                  <h4>{{ item.title }}<el-icon color="#004fff">
                      <ArrowRightBold />
                    </el-icon></h4>
                </router-link>
                <p class="text" style="margin: 1rem 0">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer2Component />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted } from 'vue'
import useUserStore from '@/stores/user.js'
import Footer2Component from '@/components/Footer2Component.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
// import router from '@/router/index.js'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
// ------------------------------ //
// 方法
// ------------------------------ //

// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '2-6'
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //

import { ArrowRight, Right, ArrowRightBold } from '@element-plus/icons-vue'
import Img1 from '@/assets/imgs/capabilityMA/capalibityMA-1.png'
import Img2 from '@/assets/imgs/capabilityMA/capalibityMA-2.jpeg'
import Img3 from '@/assets/imgs/capabilityMA/capalibityMA-3.jpeg'
import Img4 from '@/assets/imgs/capabilityMA/capalibityMA-4.jpeg'
import Img5 from '@/assets/imgs/capabilityMA/capalibityMA-5.jpeg'
import Img6 from '@/assets/imgs/capabilityMA/capalibityMA-6.jpeg'
import Img7 from '@/assets/imgs/capabilityMA/capalibityMA-7.jpeg'
import Avatar1 from '@/assets/imgs/capabilityMA/capalibityMA-avatar-1.jpeg'
import Avatar2 from '@/assets/imgs/capabilityMA/capalibityMA-avatar-2.png'
import Avatar3 from '@/assets/imgs/capabilityMA/capalibityMA-avatar-3.jpeg'

import { reactive } from 'vue';

const clients = reactive([
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/how-we-help-clients/m-and-a-strategy-due-diligence', name: 'M&A 전략 및 실사' },
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/how-we-help-clients/separations-ipos', name: '분리 및 IPO(기업공개)' },
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/how-we-help-clients/learning-and-capability-building', name: '학습 및 역량 강화' },
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/how-we-help-clients/integration', name: '통합' },
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/how-we-help-clients/joint-ventures-and-alliances', name: '합작투자 및 제휴' },
])

const approach = reactive({
  title: '우리의 실적은 타의 추종을 불허합니다. 전 세계 최대 규모의 거래 대부분을 지원하며 업계를 재편하는 프로그래매틱 거래 전문가를 포함한 선도적인 글로벌 기업 및 임원들과 성공적으로 협력하고 있습니다. 우리의 M&A 클라이언트 서비스는 몇 가지 중요사항에 중점을 두고 있습니다:',
  list: [
    { b: '포트폴리오 변혁. ', text: '연구 결과에 따르면 정적인 포트폴리오는 낮은 성과를 보입니다. 반대로 능동적인 기업들은 프로그래매틱 M&A와 선택적 매각을 통해 지속적으로 포트폴리오를 더 나은 산업 노출 및 자산으로 전환합니다. 최고의 기업들은 끊임없이 발전합니다. 우리는 클라이언트가 현명하고 효과적으로 이를 수행하도록 지원합니다.' },
    { b: '완벽한 엔드 투 엔드 딜리버리.', text: '전략 및 자본 계획부터 최종 통합 실행 단계까지 우리는 클라이언트가 주주 가치 실현을 위한 최적의 경로를 식별하도록 지원합니다.' },
    { b: '전략 실행. ', text: '전략과 M&A 프로그램을 충분히 연계하는 기업은 거의 없습니다. 우리는 기업들이 이러한 연결을 명확하게 만들고 거래 (인수 및 분리)를 위한 명확한 청사진으로 전환하도록 지원합니다.' },
    { b: '완벽한 실행.', text: '거래 또는 통합/분리 과정에서 언제든지 문제가 발생할 수 있습니다. 우리는 클라이언트가 장애물을 조기에 파악하고 제거하도록 지원합니다.' },
    { b: '통찰력 확보. ', text: '고유한 상황을 반영하여 시간이 지남에 따라 거래 접근 방식을 개선하도록 클라이언트를 지원합니다.' },
    { b: '학습 및 역량 강화. ', text: '우리는 M&A를 독보적이고 반복 가능한 경쟁 우위로 만들기 위해 클라이언트의 스킬 향상에 매일 그리고 보다 프로그래매틱한 방식으로 집중합니다.' }
  ]
})

const capabilites = [
  { title: 'M&A 협의회', text: '우리의 클라이언트는 전 세계 30명 이상의 가장 경험이 풍부한 통합 전문가와 컨설팅을 통해 가장 까다로운 M&A 문제에 대한 독점적이고 즉각적인 조언을 받을 수 있습니다.' },
  { title: 'M&A, 합병 통합, 그리고 합작투자 및 제휴 컨퍼런스', text: '최고 평점을 받는 이러한 이벤트는 수백 명의 고위 임원들이 조직의 M&A, 합작투자 및 제휴 전략와 관련된 최신 업계 문제에 대한 지속적인 업데이트를 서포트합니다.' },
  { title: 'M&A 개론 및 독자적인 CXO 어플리케이션', text: '우리는 M&A의 모든 과정에 대한 최신 사고방식을 대입하여 기업 실적에 대한 독점적인 인사이트를 제공하는 CEO 및 CFO 어플리케이션을 제작합니다.' },
  { title: '글로벌 CFO 포럼', text: '우리는 세계를 이끌어가는 CFO들을 한 자리에 모아 다양한 비즈니스 유닛을 가진 기업의 재무 최고책임자들이 직면하는 중요한 문제들을 논의하는 기회를 제공합니다.' },
  { title: '통합 리더십 포럼', text: '통합 경영진을 위한 이 집중적인 소그룹 프로그램에서는 가치 포착을 관리하고, 성공적인 첫날을 보장하며, 가장 복잡한 거래 중에도 최고의 인재를 유지하는 방법과 같은 핵심 인사이트를 다룹니다. 참가자들로부터 호평을 받고 있는 대화형 접근 방식은 M&A에 대한 이해와 자신감을 빠르게 높여줍니다.' },
  { title: '디지털 플랫폼 및 학습', text: '강력한 디지털 프로젝트 추적 및 시너지 식별 도구를 포함하여 원활한 M&A 가치 포착을 가속화할 수 있는 20가지 이상의 디지털 지원 도구 세트를 제공합니다. 디지털 학습 프로그램은 최고의 통합 경영진의 시뮬레이션과 코칭을 통해 통합 리더와 팀이 가장 까다로운 통합 과제에 대비할 수 있도록 지원합니다.' },
]

const insights = reactive([
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/our-insights/global-m-and-a-market-defies-gravity-in-2021-second-half', img: Img4, title: '2021년 하반기 글로벌 M&A 시장, 중력을 거스르다.', text: '2022년 3월 16일 - 하지만 앞으로 몇 달간 M&A 시장이 활발하게 유지될 가능성은 얼마나 될까요?', tip: '기사' },
  { link: 'https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/how-one-approach-to-m-and-a-is-more-likely-to-create-value-than-all-others', img: Img5, title: 'M&A 가치 창출의 효과적인 접근 방식', text: '2021년 10월 13일 - 20년간의 연구 결과에 따르면 대형 거래도 여전히 중요하지만 프로그래매틱 M&A 전략은 지속적으로 더 많은 가치를 창출하고 있으며..', tip: '기사 - 맥킨지 분기별' },
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/our-insights/post-close-excellence-in-large-deal-m-and-a', img: Img6, title: '대형 거래 M&A 사후 우수성', text: '2021년 6월 29일 - 최대 규모의 거래 성공 사례는 통합 실행 과정에서 네 가지 주요 실행 방식을 따릅니다.', tip: '기사' },
  { link: 'https://www.mckinsey.com/capabilities/m-and-a/our-insights/through-a-different-lens-a-mckinsey-perspective-on-separations', img: Img7, title: '새로운 관점으로 바라보다 : 분리에 대한 맥킨지의 시각', text: '2020년 5월 31일 - 기업들은 성공하기 위해 기존의 M&A 접근 방식을 뛰어넘는 방식으로 분할을 고려해야 합니다.', tip: '기사' },
])

const people = reactive([
  {
    link: 'https://www.mckinsey.com/our-people/nick-leung',
    cover: Avatar1,
    name: '닉 량',
    tip: '수석 파트너, 홍콩',
    desc: '다양한 산업 분야에 걸쳐 글로벌 및 중국 기업에 자문 서비스를 제공하고 있습니다.'
  },
  {
    link: 'https://www.mckinsey.com/our-people/jeff-rudnicki',
    cover: Avatar2,
    name: '제프 루드니키',
    tip: '수석 파트너, 보스턴',
    desc: '우리 글로벌 M&A 전략 분야의 리더로서 본인의 경험을 활용하여 100건 이상의 클라이언트 계약을 지휘합니다.'
  },
  {
    link: 'https://www.mckinsey.com/our-people/mieke-van-oostende',
    cover: Avatar3,
    name: '미에케 반 우스텐데',
    tip: '수석 파트너, 브뤼셀',
    desc: '금융 서비스 부문의 클라이언트에게 서비스를 제공하며 특히 전략, 조직 및 리스크 관리 부문에 있어 전 세계적인 명성을 얻고 있습니다.'
  },
])

</script>

<style lang="scss" scoped>
@import url("@/assets/scss/common.scss");
@media screen and (max-width: 768px) {
  .people {
    flex-direction: row !important;
  }
}

// PC
@media screen and (min-width: 1024px) {
  #p25 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/shutterstock_1317797996-scaled.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          padding: 0 50px;
          background: url('@/assets/image/shutterstock_rocket-ship-blue-background-300x137-1.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 42px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      padding: 30px 200px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;
    }

    .a1x {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .b1 {
        width: 49.5%;
        height: 220px;
        padding: 15px 100px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    .a2 {
      width: 100%;
      height: 400px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 280px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #p25 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .content {
      width: 100%;

      .carousel {
        width: 100%;
        height: 500px;

        .carousel-a1 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/shutterstock_1317797996-scaled.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .carousel-a2 {
          width: 100%;
          height: 500px;
          background: url('@/assets/image/shutterstock_rocket-ship-blue-background-300x137-1.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .carousel-b1 {
            font-size: 30px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .carousel-c1 {
            margin-top: 50px;
            font-size: 24px;
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .a1 {
      width: 100%;
      padding: 15px 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #313131;
      text-align: center;
    }

    .a1x {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      .b1 {
        width: 100%;
        padding: 15px 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    .a2 {
      width: 100%;
      height: 400px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      background-color: white;
      color: #313131;

      .b1 {
        width: 100%;

        .c1 {
          display: flex;

          .d1 {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 280px;
            margin: 10px;
            text-align: center;
            border-radius: 4px;
            background: var(--el-color-danger-light-9);
            color: var(--el-color-danger);
            font-size: 12px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}
</style>
