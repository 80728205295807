<template>
  <div id="p26">
    <HeaderComponent />
    <div class="alliances page">
      <div class="box-right">
        <div class="flex">
          <el-image class="flex-1" :src="Img1"></el-image>
          <div class="info flex column flex-1 absoult top-center" style="right: 0;margin-left: 70%;">
            <h1 class="title end" style="font-size: 4rem;">개방적인 연합 및 인수 생태계</h1>
            <span class="subtitle end" style="margin-top: 1rem;">고객에게 최상의 서비스를 제공합니다</span>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="flex column">
          <p class="text big">고객을 지원하기 위해 급변하는 세계에서는 지속적으로 혁신하고 능력을 강화해야 합니다. 클라우드 및 인공 지능부터 지속 가능성, 변화 가속화 등 다양한 분야까지
            개방형 생태계는 고객에게 종단 간 영향 파트너로서 역할을 할 수 있도록 합니다.
          </p>
          <UpModel :list="upModelData"></UpModel>
        </div>
      </div>
      <div class="box">
        <h5 class="subtitle center">우리의 결합된 역량</h5>
        <div class="grid grid-3">
          <div class="item" v-for="item in capabilites">
            <h3 class="title">{{ item.title }}</h3>
            <span class="text">{{ item.text }}</span>
          </div>
        </div>
      </div>
      <div class="box">
        <h5 class="subtitle center">우리의 결합된 역량</h5>
        <div class="flex item shadow" style="gap: 1rem;">
          <el-image style="height: auto;" class="img" :src="Img2" fit="contain"></el-image>
          <div class="info flex column flex-1">
            <router-link class="title"
              to="https://www.mckinsey.com/about-us/new-at-mckinsey-blog/mckinsey-alliances-bring-the-power-of-generative-ai-to-clients">
              <h2>맥킨지의 전략적 연합 생태계는 고객에게 생성적인 인공지능의 힘을 가져다줍니다.
                <el-icon color="#004fff">
                  <ArrowRightBold />
                </el-icon>
              </h2>
            </router-link>
            <span class="text">2024년 4월 2일 - 맥킨지의 AI인 QuantumBlack은 생성 인공 지능 구현에 필요한 정교한 기능을 제공하는 성공적인 전략적 제휴 생태계를
              구축했습니다.
            </span>
          </div>
        </div>
      </div>
      <div class="box box-full" style="background: #eee;width: 100%;padding: 3rem ;">
        <h2 class="subtitle center">에코시스템에 대해 자세히 알아보기</h2>
        <div class="grid" style="width: 100%;">
          <div class="item" style="width: 100%;padding: 2rem 0;background: #fff;">
            <h5 class="subtitle center">주요 영향</h5>
            <div class="grid grid-4">
              <div class="item shadow" v-for="item in alliances">
                <el-image :src="item" style="height: 5rem;width: 100%;" fit="contain"></el-image>
              </div>
            </div>
          </div>
          <div class="item" style="width: 100%;padding: 2rem 0;background: #fff;">
            <h5 class="subtitle center">주요 인수</h5>
            <div class="grid grid-4">
              <div class="item shadow" v-for="item in acq">
                <el-image :src="item" style="height: 5rem;width: 100%;" fit="contain"></el-image>
              </div>
            </div>
          </div>
          <div class="item" style="width: 100%;padding: 2rem 0;background: #fff;">
            <h5 class="subtitle center">기능 네트워크</h5>
            <el-radio-group v-model="selectedLogo" size="large" style="justify-content: center;width: 100%;">
              <el-radio-button label="기술 및 애플리케이션" :value="0" />
              <el-radio-button label="데이터 및 분석" :value="1" />
              <el-radio-button label="구현 및 변경" :value="2" />
            </el-radio-group>
            <div class="grid grid-4" style="filter: grayscale(1);">
              <div class="item shadow" v-for="item in logogroup[selectedLogo]">
                <el-image :src="item" style="height: 5rem;width: 100%;" fit="contain"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-full box">
        <h5 class="subtitle center">주요 영향</h5>
        <div class="grid" style="grid-gap: 0;">
          <div class="flex" style="background: #051c2c; color: #fefefe;" v-for="(item, index) in featured"
            :class="(index % 2) === 1 ? 'reverse' : ''">
            <div class="flex-1 absoult absoult-top-mobile" style="overflow: hidden;"
              :class="(index % 2) === 0 ? 'absoult-left' : 'absoult-right'">
              <el-image :src="item.img" fit="fill" style="width: 100%;height: 100%;"></el-image>
            </div>
            <div class="info flex column absoult-left absoult-bottom-mobile" style="width: 50%;"
              :style="(index % 2) === 0 ? 'margin-left: auto' : 'margin-right: auto'">
              <div style="padding: 3rem;min-height: 20rem;"
                :style="(index % 2) === 1 ? 'margin-left: 2rem' : 'margin-right: 2rem'">
                <h1 class="title" style="margin-bottom: 2rem;">{{ item.title }}</h1>
                <ul style="margin-left: 2rem;">
                  <li v-for="text in item.text">{{ text }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box box-full" style="background: #eee;width: 100%;padding: 3rem ;">
        <h5 class="subtitle center">우리 사람들</h5>
        <div class="grid grid-2" style="gap: 0.2rem;">
          <div class="item flex column" style="background: #fff;padding: 3rem;align-items: center;"
            v-for="item in people">
            <el-avatar :src="item.avatar" class="avatar" style="width: 10rem;height: 10rem;margin-bottom: 2rem;" />
            <div class="info flex column" style="align-items: center;">
              <router-link class="title" :to="item.link">
                <h2>{{ item.name }}</h2>
              </router-link>
              <h5 class="subtitle" style="margin-bottom: 0;">{{ item.sub }}</h5>
              <el-divider style="margin: 1rem 0"></el-divider>
              <span class="text center">{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box box-full" style="background: #051c2c;width: 100%;margin-top: 0;">
        <div class="flex">
          <div class="flex-1 absoult absoult-right" style="overflow: hidden;">
            <el-image :src="Img3" fit="contain" style="width: 100%;height: 100%;margin: 0 auto;"></el-image>
          </div>
          <div class="info flex column absoult-left" style="width: 50%;">
            <div style="padding: 3rem;color: #fefefe;">
              <h1 class="title" style="margin-bottom: 2rem;">노이즈 마케팅 이상의 가치 창출</h1>
              <h4 class="subtitle">전략부터 규모에 이르기까지 기술을 통한 약속을 실현합니다.</h4>
              <router-link class="see" style="color: #fefefe"
                to="https://www.mckinsey.com/capabilities/mckinsey-digital/how-we-help-clients??cid=njt-ste-crk-mbm-mbm--2403-promo02-njt-bam-web">
                <h5>시작<el-icon color="#fefefe">
                    <Right />
                  </el-icon></h5>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <el-divider></el-divider>
        <h5 class="subtitle">관련</h5>
        <div class="grid">
          <div class="item shadow flex" style="gap: 1rem">
            <el-image :src="RelatedImg" class="flex-1" fit="fill"></el-image>
            <div class="flex-1">
              <router-link class="title" to="https://www.mckinsey.com/solutions">
                <h2>솔루션<el-icon color="#004fff">
                    <ArrowRightBold />
                  </el-icon></h2>
              </router-link>
              <p class="text">맥킨지의 전용 솔루션이 어떻게 어느 때보다 빠르게 통찰력과 영향력을 제공하는 데 도움이 되는지 자세히 알아보세요.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer2Component />
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted, ref } from 'vue'
import useUserStore from '@/stores/user.js'
import Footer2Component from '@/components/Footer2Component.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
// import router from '@/router/index.js'
// ------------------------------ //
// 数据
// ------------------------------ //
const userStore = useUserStore()
const activeName = ref('first')
// ------------------------------ //
// 方法
// ------------------------------ //

// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {
  userStore.activeIndex = '2-4'
})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => { })
// ------------------------------ //


import UpModel from '@/components/upModel.vue'
import Img1 from '@/assets/imgs/alliance-1.webp'
import Img2 from '@/assets/imgs/alliance-2.jpg'
import Img3 from '@/assets/imgs/alliance-3.webp'
import FeatureImg1 from '@/assets/imgs/alliance-featured-1.webp'
import FeatureImg2 from '@/assets/imgs/alliance-featured-2.webp'
import FeatureImg3 from '@/assets/imgs/alliance-featured-3.webp'
import FeatureImg4 from '@/assets/imgs/alliance-featured-4.webp'
import RelatedImg from '@/assets/imgs/alliance-related.webp'
import avatar1 from '@/assets/imgs/alliance-avatar-1.webp'
import avatar2 from '@/assets/imgs/alliance-avatar-2.webp'
import logo1 from '@/assets/imgs/alliance/alliance-logo-1.avif'
import logo2 from '@/assets/imgs/alliance/alliance-logo-2.avif'
import logo3 from '@/assets/imgs/alliance/alliance-logo-3.avif'
import logo4 from '@/assets/imgs/alliance/alliance-logo-4.avif'
import logo5 from '@/assets/imgs/alliance/alliance-logo-5.avif'
import logo6 from '@/assets/imgs/alliance/alliance-logo-6.avif'
import logo7 from '@/assets/imgs/alliance/alliance-logo-7.avif'
import logo8 from '@/assets/imgs/alliance/alliance-logo-8.avif'
import logo9 from '@/assets/imgs/alliance/alliance-logo-9.avif'
import logo10 from '@/assets/imgs/alliance/alliance-logo-10.avif'
import logo11 from '@/assets/imgs/alliance/alliance-logo-11.avif'
import logo12 from '@/assets/imgs/alliance/alliance-logo-12.avif'
import logo13 from '@/assets/imgs/alliance/alliance-logo-13.avif'
import logo14 from '@/assets/imgs/alliance/alliance-logo-14.avif'
import logo15 from '@/assets/imgs/alliance/alliance-logo-15.avif'
import logo16 from '@/assets/imgs/alliance/alliance-logo-16.avif'
import logo17 from '@/assets/imgs/alliance/alliance-logo-17.avif'
import logo18 from '@/assets/imgs/alliance/alliance-logo-18.avif'
import logo19 from '@/assets/imgs/alliance/alliance-logo-19.avif'
import logo20 from '@/assets/imgs/alliance/alliance-logo-20.avif'
import logo21 from '@/assets/imgs/alliance/alliance-logo-21.avif'
import logo22 from '@/assets/imgs/alliance/alliance-logo-22.avif'
import logo23 from '@/assets/imgs/alliance/alliance-logo-23.avif'
import logo24 from '@/assets/imgs/alliance/alliance-logo-24.avif'
import logo25 from '@/assets/imgs/alliance/alliance-logo-25.avif'
import logo26 from '@/assets/imgs/alliance/alliance-logo-26.avif'
import logo27 from '@/assets/imgs/alliance/alliance-logo-27.avif'
import logo28 from '@/assets/imgs/alliance/alliance-logo-28.avif'
import logo29 from '@/assets/imgs/alliance/alliance-logo-29.avif'
import logo30 from '@/assets/imgs/alliance/alliance-logo-30.avif'
import logo31 from '@/assets/imgs/alliance/alliance-logo-31.avif'
import logo32 from '@/assets/imgs/alliance/alliance-logo-32.avif'
import logo33 from '@/assets/imgs/alliance/alliance-logo-33.avif'
import logo34 from '@/assets/imgs/alliance/alliance-logo-34.avif'
import logo35 from '@/assets/imgs/alliance/alliance-logo-35.avif'
import logo36 from '@/assets/imgs/alliance/alliance-logo-36.avif'
import logo37 from '@/assets/imgs/alliance/alliance-logo-37.avif'
import logo38 from '@/assets/imgs/alliance/alliance-logo-38.avif'
import logo39 from '@/assets/imgs/alliance/alliance-logo-39.avif'
import logo40 from '@/assets/imgs/alliance/alliance-logo-40.avif'
import logo41 from '@/assets/imgs/alliance/alliance-logo-41.avif'
import logo42 from '@/assets/imgs/alliance/alliance-logo-42.avif'
import logo43 from '@/assets/imgs/alliance/alliance-logo-43.avif'

import { ArrowRightBold, Right } from '@element-plus/icons-vue'

const alliances = [logo1, logo2, logo3, logo4, logo5, logo6]
const acq = [logo7, logo8, logo9, logo10, logo11, logo12]
const tec = [logo13, logo14, logo15, logo16, logo17, logo18, logo19, logo20, logo21, logo42, logo23, logo24, logo25, logo26, logo27, logo28]
const data = [logo29, logo30, logo31, logo42, logo32, logo43, logo33, logo34, logo35, logo36]
const imp = [logo37, logo38, logo39, logo40, logo41, logo35]
const logogroup = [tec, data, imp]

const selectedLogo = ref(0)

const upModelData = [
  { data: '500+', label: '에코시스템 구성원' },
  { data: '20+', label: '인수한 기업 수' },
  { data: '750+', label: '고객 수' }
]

const capabilites = [
  { title: '기술 및 애플리케이션', text: '저희는 고객이 기술 투자의 가치를 최대한 활용하고 업무 방식을 혁신하는 도구를 갖출 수 있도록 지원합니다.' },
  { title: '데이터 및 분석', text: '광범위한 부문 데이터와 고급 분석의 결합된 힘은 고객이 더 많은, 더 나은, 더 빠른 인사이트를 창출할 수 있도록 지원합니다.' },
  { title: '구현 및 변화', text: '크리테오의 에코시스템을 통해 인사이트를 넘어 고객과 함께 엔드투엔드 혁신을 실현할 수 있습니다.' }
]

const featured = [
  { img: FeatureImg1, title: '아마존 웹 서비스(AWS)와 맥킨지', text: ['세계 공급망의 생산 및 물류를 연결하기 위한 최초의 자동차 산업용 IoT 클라우드를 구축했습니다.', '현재 진행 중인 협력을 통해 유럽 자동차 회사가 전체 조직에서 2025년까지 30%의 효율성 향상을 달성할 수 있도록 지원하여 두 자릿수의 억 달러 효과를 실현할 수 있도록 지원하고 있습니다.'] },
  { img: FeatureImg2, title: '구글 클라우드와 맥킨지', text: ['공공 기업이 정전 및 운영 비용을 줄일 수 있도록 식물 관리 솔루션을 개발했습니다.', '위성 이미지에서 선진적인 분석 기술을 활용하여 식물의 성장을 예측했습니다. 솔루션은 식물로 인한 정전을 10%에서 15% 감소시키고 미래의 식물 성장에 대한 시야를 높일 것으로 예상됩니다.'] },
  { img: FeatureImg3, title: '마이크로소프트와 맥킨지', text: ['첨단 OEM 업체가 5G 및 I4.0 기술을 활용하여 생산성을 120% 향상시킨 등대 공장을 건설하는 데 도움을 주었습니다.', '또한 NAT은 최첨단 IoT 플랫폼 및 교차 기능 민첩성 팀을 구축하여 동종 사이트보다 3배 빠른 12개월 이내에 25개 이상의 사용 사례를 구축할 수 있도록 지원했습니다.'] },
  { img: FeatureImg4, title: 'SAP와 맥킨지', text: ['글로벌 제약 회사 SAP S/4HANA에 전환을 재구성하고 그로부터 가치를 추출하는 데 도움을 주었습니다.', '가치 요인을 확인하고 양적으로 평가하여 30개 이상의 ERP 시스템을 통합해 계획 지출을 최적화하고 유지 보수 비용을 줄였습니다.'] },
]

const people = [
  { link: 'https://www.mckinsey.com/our-people/peter-dahlstrom', avatar: avatar1, name: '피터 달스트롬 ', sub: '너, 런던 수석 파트너 ', text: '맥킨지의 제휴 및 인수 글로벌 책임자이며 주로 통신 분야의 금융, 보험 등의 주요 기관에 서비스를 제공하고 있습니다.' },
  { link: 'https://www.mckinsey.com/our-people/liz-ericson', avatar: avatar2, name: '리즈 에릭슨', sub: '런던 파트너', text: '소비자를 대상으로 하는 기관들에게 디지털 프로젝트를 진행하며, 옴니채널 전략, 조직, 마케팅, 제품 등을 담당합니다.' }
]

</script>

<style lang="scss" scoped>
@import url("@/assets/scss/common.scss");

.img {
  width: 20%;

  :deep(.el-image__inner) {
    object-position: top;
  }
}

@media (max-width: 480px) {
  .img {
    width: 100%;
  }
}

// PC
@media screen and (min-width: 1024px) {
  #p26 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    .content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      video {
        width: 1200px;
        height: 500px;
        padding: 10px 100px;
        object-fit: cover;
        background-color: white;
      }

      .a1 {
        width: 100%;
        padding: 30px 200px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: #313131;
        text-align: center;
      }

      .a2 {
        width: 100%;
        height: 250px;
        background-color: white;

        .b1 {
          padding: 30px;
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1024px) {
  #p26 {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    overflow: auto;

    .divider {
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
    }

    .content {
      width: 100%;

      video {
        width: 100%;
        height: 500px;
        object-fit: cover;
        background-color: white;
      }

      .a1 {
        width: 100%;
        padding: 15px 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: #313131;
        text-align: center;
      }

      .a2 {
        width: 100%;
        height: 500px;
        background-color: white;

        .b1 {
          padding: 30px;
        }
      }
    }
  }
}
</style>
