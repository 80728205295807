<template>
  <div id="footer">
    <div class="a1">
      <h3>개인정보/이용약관</h3>
      <p>
        MORGAN CREEK은 모건 크릭 캐피탈 관리, LLC의 등록 상표입니다. 모건 크릭 캐피탈 관리는 또한
        영국에서 상표 등록을 완료한 상호입니다 (등록 번호 UK00003088978). 모건 크릭 캐피탈 관리,
        LLC는 SEC 등록된 투자 자문사입니다. SEC 등록은 특정 수준의 능력이나 훈련을 의미하지
        않습니다. 이 웹사이트에 있는 정보는 투자 기금에 대한 관심을 판매하거나 구매를 유도하는 것이
        아니며, 투자 관리 또는 자문 서비스 제공에 대한 제안도 아닙니다. 모건 크릭 캐피탈 관리, LLC
        및 그 자회사는 법률, 회계 또는 세무 상담 서비스를 제공하지 않습니다.
      </p>
    </div>
    <div class="a2">
      © Copyright 2024 Morgan Creek Capital Management, LLC. All rights reserved.
    </div>
  </div>
</template>

<script setup>
// ------------------------------ //
// 引入
// ------------------------------ //
import { onMounted, onUnmounted } from 'vue'
// ------------------------------ //
// 数据
// ------------------------------ //
// ------------------------------ //
// 方法
// ------------------------------ //
// ...
// ------------------------------ //
// onMounted
// ------------------------------ //
onMounted(() => {})
// ------------------------------ //
// onUnmounted
// ------------------------------ //
onUnmounted(() => {})
// ------------------------------ //
</script>

<style lang="scss" scoped>
// PC
@media screen and (min-width: 1024px) {
  #footer {
    width: 100%;
    height: 250px;

    .a1 {
      width: 100%;
      height: 190px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
      background-color: #445b41;

      h3 {
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
      }

      p {
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .a2 {
      width: 100%;
      height: 60px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #253b23;
    }
  }
}
// Mobile
@media screen and (max-width: 1024px) {
  #footer {
    width: 100%;

    .a1 {
      width: 100%;
      padding: 15px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
      background-color: #445b41;

      h3 {
        width: 100%;
      }

      p {
        width: 100%;
      }
    }

    .a2 {
      width: 100%;
      height: 60px;
      padding: 0 15px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #253b23;
    }
  }
}
</style>
