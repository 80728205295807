<template>
  <div id="p33sp7">
    <HeaderComponent />
    <div class="a1">
      <!-- 按钮组 -->
      <div class="button-group">
        <button @click="activeTab = 'tab1'" :class="{ active: activeTab === 'tab1' }">
          코스피
        </button>
        <button @click="activeTab = 'tab2'" :class="{ active: activeTab === 'tab2' }">
          나스닥
        </button>
      </div>
      <br />
      <!-- 内容区域 -->
      <div class="content">
        <div v-show="activeTab === 'tab1'" class="combined-tradingview">
          <!-- Tab 1 内容 -->
          <AsianOne1></AsianOne1>
          <!-- Tab 1 内容 -->
        </div>
        <div v-show="activeTab === 'tab2'">
          <!-- Tab 2 内容 -->
          <AsianOne2></AsianOne2>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import AsianOne1 from './AsianOne1.vue'
import AsianOne2 from './AsianOne2.vue'
import useUserStore from '@/stores/user.js'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
export default {
  data() {
    return {
      activeTab: 'tab1' // 默认显示第一个标签页的内容
    }
  },
  components: {
    FooterComponent,
    HeaderComponent,
    AsianOne1,
    AsianOne2
  },
  mounted() {
    const userStore = useUserStore()
    userStore.activeIndex = '3-3'
  }
}
</script>

<style scoped>
#p33sp7 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .a1 {
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    overflow: auto;

    .button-group {
      display: flex;
      justify-content: center;
      /* margin: 0 auto;  */
    }

    .button-group button {
      padding: 10px 50px;
      border: none;
      background-color: #dad9d9;
      cursor: pointer;
      color: grey;

      transition: all 0.3s;
    }

    .button-group button:first-child {
      border-radius: 13px 0 0 13px;
    }

    .button-group button:last-child {
      border-radius: 0 13px 13px 0;
    }

    .button-group button.active {
      background-color: #000000; /* 选中按钮的背景颜色保持不变 */
      color: rgb(255, 255, 255); /* 选中按钮的文字颜色变为黑色 */
    }

    .combined-tradingview {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 500px;
    }
    .combined-tradingview1 {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 500px;
    }

    .widget-container {
      width: 100%;
      height: 100%;
    }
    .screener-container {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
